import React from 'react';
import {Modal} from './TourModal'
import './Enrollment.css';
import Navbar from '../components/Navbar.jsx';
import Footer from '../components/Footer.jsx';
import card1 from '../img/boy_reading.jpg'
import card2 from '../img/superboy.jpg'

class Enrollment extends React.Component {
    render() {
        return (
            <div className='whole-page'>
                <Modal/>
                <div className='page-top'>
                    <br></br>
                    <div className='title-anchor'>
                        <a href='/'>
                            <img src={require('../img/logoWhite.png')} alt='Just Kids Preschool Logo' className='center' />
                            <h3 className='page-name text-center' >100% Chinese Immersion Program</h3>
                        </a>

                    </div>

                    <Navbar />
                    <div className='container mt-5'>
                        <div>
                            <br></br>
                            <h2 className='page-name text-center'>Enrollment & Tuition</h2>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 ml-auto">
                                        <div className="row mt-3 mb-3">
                                            <div className="col-sm-6 p-2">
                                                <a href="#enrollment-section">
                                                    <div className="pic-select card">
                                                        <img className='card-img-top' src={card1} alt="Logo" />
                                                        <div className="card-body enrollment-cards">
                                                            <h5 className="card-title text-primary ">Enrollment</h5>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <a href='#fees-section'>
                                                    <div className="pic-select card">
                                                        <img className='card-img-top' src={card2} alt="Logo" />
                                                        <div className="card-body enrollment-cards">
                                                            <h5 className="card-title text-primary ">Tuition</h5>
                                                        </div>
                                                    </div>

                                                </a>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                                {/* <div className='mb-5'>
                                We accept enrollment applications based on availability and first come first serve. Just Kids Preschool
                                does not discriminate on the basis of race, color, ethnic origin, religion, gender, or
                                sexual orientation in our educational and administrative policies.
                            </div>
                            <div>Your application is complete once the Online Application form AND the $100 APPLICATION FEE have been received.</div>
                            <div>Please remit $100 Application Fee via Check or Money Order and send to:</div><br />
                            <div>Just Kids Preschool<br />
                                5727 Geary Blvd<br />
                                San Francisco, CA 94121</div>
                            <br />
                            <Link className="btn btn-warning enroll-btn mb-5" to="/EnrollmentForm">Enroll Now!</Link> */}
                            </div>
                        </div>

                    </div>
                    <br></br><br></br><br></br><br></br><br></br>

                </div>
                <div className='container'>
                    <div className='philosophy-lower-container mt-5'>
                        {/* <h3 className='topic-header'>Enrollment Process</h3>
                        <br></br>
                        <h5 className='topic-header'>Step 1</h5>
                        <p className="mb-5">
                            Fill out enrollment application and mail or drop off the $100 application fee to:
                            <br></br><br></br>
                            5727 Geary Blvd <br></br>
                            San Francisco, CA 94121
                        </p>
                        <h5 className='topic-header'>Step 2</h5>
                        <p className="mb-5">
                            Once application has been accepted there will be a $200 registration fee.
                        </p>
                        <h5 className='topic-header'>Step 3</h5>
                        <p className="mb-5">
                            First and last month's tuition is due and payable 90 days prior to entry.
                        </p> */}
                        <h3 className='topic-header mb-3' id='enrollment-section'>Enrollment</h3>
                        <p className='mt-3'>
                            </p>
                        <p>
                            <i className="fas fa-star fa-lg mr-3"></i> Schedule a tour with us here: <a href="https://directory.legup.care/childcare/just-kids-preschool-san-francisco" target="blank">Schedule Tour</a> <br></br>
                            <i className="fas fa-star fa-lg mr-3"></i> Fill out an application ($100) <br></br>
                            <i className="fas fa-star fa-lg mr-3"></i> Pay a registration fee after your application has been approved<br></br>
                            <i className="fas fa-star fa-lg mr-3"></i> Put down a deposit and first month of tuition<br></br>
                            <i className="fas fa-star fa-lg mr-3"></i> Fill out paperwork and submit it to us by the first day of school <br></br>
                        </p>
                        <p className='mb-3'>
                            The enrollment process will start with completing an enrollment form.
                            This form gives us important information about your child so that we
                            can provide the best possible care. Your child's health and safety is our top priority
                            so all forms need to be accurately completed.
                            </p>
                        <h5>Enrollment Priority</h5>
                        <p className='mb-5'>
                            Students enrolling into Just Kids Preschool are admitted on a first-come, first-serve
                            basis and availability.
                                <br></br>
                            Priority enrollment consideration is given to re-enrolling students, siblings, and
                            students that are transfering from Baby Prodigy Nursery.
                            </p>
                            <a className="btn btn-warning enroll-btn mb-5 center" href="https://directory.legup.care/childcare/just-kids-preschool-san-francisco" target="blank">Check Availability / Apply</a>
                        {/* <Link className="btn btn-warning enroll-btn mb-5 center" to="/EnrollmentForm" onClick={() => window.scrollTo(0, 0)}>Enrollment Application</Link> */}
                        {/* <p>
                            To complete the enrollment application process, please fill out enrollment application and mail or drop off the $100 application fee to:
                            </p>
                        <p className="mb-5">
                            Just Kids Preschool <br></br>
                            5727 Geary Blvd <br></br>
                            San Francisco, CA 94121

                            </p> */}
                        <h3 className='topic-header mb-3' id='fees-section'>Fees, Discounts & Payment</h3>

                        <ul>
                            <li>
                                Enrollment Application Fee: $100
                            </li>
                            <br></br>
                            <li>
                                Registration Fee after acceptance: $200
                            </li>
                            <br></br>
                            <li>
                                Potty Training Fee: $100
                            </li>
                            <br></br>
                            <li>
                                Materials Fee: $100
                            </li>
                            <br></br>
                            <li>
                                First and last month's tuition is due and payable 90 days
                                prior to entry
                            </li>
                            <br></br>
                            <li>
                                Sibling discount on registration: $100 (50% off)
                            </li>
                            <br></br>
                            <li>
                                Siblings monthly tuition discount: 5%
                            </li>
                            <br></br>
                            <li>
                                Baby Prodigy Nursery transfer student: 5% off monthly tuition
                            </li>
                            <br></br>
                            <li>
                                We accept ELS vouchers
                            </li>
                        </ul>
                        <h3 className='topic-header mt-5'>Tuition Payment & Terms</h3>
                        <p>
                            Payments are due in advance and are processed on the 1st of each month by check. Checks will be
                            paid to the order of Just Kids Preschool.
                        </p>
                        <h5>Tuition</h5>
                        <table className="table table-striped bg-light text-center">
                            <thead>
                                <tr className="text-muted">
                                    <th></th>
                                    <th>Days</th>
                                    <th>Hours</th>
                                    <th>Monthly Tuition</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="text-muted">
                                    <th>Full-time</th>
                                    <th>Monday - Friday</th>
                                    <th>7:45AM - 5:30PM</th>
                                    <th>$2,300</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>Part-time</th>
                                    <th>3 Days</th>
                                    <th>7:45AM - 5:30PM</th>
                                    <th>$1,790</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>Part-time</th>
                                    <th>2 Days</th>
                                    <th>7:45AM - 5:30PM</th>
                                    <th>$1,300</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>Part-time</th>
                                    <th>5 Days</th>
                                    <th>7:45AM - 12:30PM</th>
                                    <th>$1,450</th>
                                </tr>
                            </tbody>

                        </table>
                        <p>
                            <b>
                                Tuition subject to change annually with a 30 day notice.
                                </b>
                            <br></br>
                            <b>
                                Lunch is provided along with two snacks throughout the day.
                            </b>
                        </p>

                        <h3 className='topic-header mt-5'>Daily/Weekly Rates - Limited Time Offer </h3>
                        <p>We are now providing part-time, daily and weekly preschool options! </p>
                        <p>We decided to add more flexible preschool options to cater to all our families during these hard times.</p>
                        <table className="table table-striped bg-light text-center">
                            <thead>
                                <tr className="text-muted">
                                    <th>Hours</th>
                                    <th>Daily Rate</th>
                                    <th>Weekly Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="text-muted">
                                    <th>7:45AM - 5:30PM</th>
                                    <th>$140</th>
                                    <th>$600</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>7:45AM - 12:30PM</th>
                                    <th>$85</th>
                                    <th>$350</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>12:30PM - 5:30PM (Child eats at home)</th>
                                    <th>$85</th>
                                    <th>$350</th>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Enrollment;