import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBiWAkjclhK5F-k1CJlf_qPI2lyQWbHwhM",
    authDomain: "justkidspreschoolsf.firebaseapp.com",
    databaseURL: "https://justkidspreschoolsf.firebaseio.com",
    projectId: "justkidspreschoolsf",
    storageBucket: "justkidspreschoolsf.appspot.com",
    messagingSenderId: "252434765518",
    appId: "1:252434765518:web:e447149afb85319f7a629f",
    measurementId: "G-ZCWCSX1H6X"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebaseApp.auth();


export { auth };
export { db }; 