import React from 'react';
import {Modal} from './TourModal'
import './Philosophy.css';
import Navbar from '../components/Navbar.jsx';
import Footer from '../components/Footer.jsx';

class Philosophy extends React.Component {
    render() {
        return (
            <div className='whole-page'>
                <Modal/>
                <div className='page-top'>
                    <br></br>
                    <div className='title-anchor'>
                        <a href='/'>
                            <img src={require('../img/logoWhite.png')} alt='Just Kids Preschool Logo' className='center' />
                            <h3 className='page-name text-center' >100% Chinese Immersion Program</h3>
                        </a>

                    </div>
                    <Navbar />
                    <div className='container container-inner mt-5'>
                        <br></br>
                        <h2 className='page-name'>Philosophy</h2>
                        <br></br>
                        <p>​​Kids Preschool was created when two mothers 
                            decided to create a place for their children to burn 
                            off energy, learn and work on their independence. 
                            We wanted our children to have a place that is safe 
                            enough for them to learn from their mistakes and learn 
                            as they work on their problem solving skills.   </p>
                        <br></br>
                    </div>

                <br></br><br></br><br></br><br></br><br></br>
                </div>

                <div className='container'>
                    <div className='philosophy-lower-container mt-5'>
                        <h3 className='topic-header'>Where is the school located and what is the age group?</h3>
                        <p>In the Outer Sunset district, this facility is located at xxx in San Francisco. 
                            I need to study some loops and recusion, time complexity, arrays and matrices,
                            linked lists, queues, stacks, heaps, sets and hashmaps, trees and binary search,
                            graphs, depth first search, breadth first search, and backtracking
                            tries, merge sort and quick sort.
                        </p>
                        <p>Some other useful content will be dynamic programming (memoization and divide-and-conquer),
                            problems with mulriple pointers or sliding windows, greedy algoriths,
                            logical/mathematical proof techniques (contradiction, contrapositive, induction, cases), 
                            object oriented programming.
                        </p>

                        <h3 className='topic-header mt-5'>How long has the preschool been in business?</h3>
                        <p>We are going to open soon!</p>

                        <h3 className='topic-header mt-5'>What is the school's education philosophy?</h3>
                        <p>The objectives of our program are: <br></br>
                            Building up strong confidence <br></br>
                            Developing social skills <br></br>
                            Enhancing esteem through self-accomplishment <br></br>
                            Encouraging cognitive development appropriate for each child's learning style <br></br>
                            Developing an appropriate pattern of dependence and independence <br></br>
                            Fostering a sense of continuity between school and the outside world <br></br>
                        </p>

                        <h3 className='topic-header mt-5'>What type of curriculum do you follow?</h3>
                        <p>Kids Preschool combines child development, bilingual 
                            language, academics, and play. Therefore, our curriculum focuses on promoting 
                            learning and development in social-emotional, physical, cognitive, and language; 
                            on reflecting what is developmentally appropriate for children of all ages; on 
                            incorporating time and materials for play, self-initiating learning, and creative 
                            expression; and on implementing in a manner that reflects responsiveness to family 
                            home values, beliefs, experiences, and language
                        </p>

                        <h3 className='topic-header mt-5'>Is my child required to know the Chinese language?</h3>
                        <p>No prior Chinese language is required of either the child or the family. Our program 
                            offers children the opportunity to learn Mandarin in an activity based environment 
                            with our experienced bilingual teaching staff in a very large setting.
                        </p>

                        <h3 className='topic-header mt-5'>What is the indoor play zone?</h3>
                        <p>
                            Our 1300 square foot creative indoor discovery play zone will provide 
                            children with a fun experience and adventure of imaginary play, which 
                            supports our curriculum. Since play is interesting and important to children, 
                            they are eager to learn new vocabulary, new physical skills, and new social 
                            behavior that will allow them to stay engaged in play.
                        </p>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Philosophy;