import React from 'react'
import './modalBooking.css'

export const Modal = () => {

    return ( 
        <div className='modal display-block'>
            <section className='modal-main mb-5'>
                <h3 className='mt-3'>Thank You For Booking a Tour</h3>
                <div>Now Scheduling...</div>
                <div className='mb-3'>Please wait...</div>
            </section>
        </div>
    )
}