import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home.jsx';
import Philosophy from './pages/Philosophy.jsx';
import Curriculum from './pages/Curriculum.jsx';
import Enrollment from './pages/Enrollment.jsx';
import EnrollmentForm from './pages/EnrollmentForm.jsx';
import Staff from './pages/Staff.jsx';
import Schedule from './pages/Schedule.jsx';
import Contact from './pages/Contact.jsx';
import Gallery from './pages/Gallery.jsx';
import Covid from './pages/Covid.jsx';
import Babysit from './pages/Babysit.jsx';
import AdminLogin from './pages/AdminLogin.jsx'
import AdminHome from './pages/AdminHome.jsx'
import AdminEnrollment from './pages/AdminEnrollment.jsx'
import AdminEnrollmentForm from './pages/AdminEnrollmentForm.jsx'
import AdminMessages from './pages/AdminMessages.jsx'
import AdminCreateTours from './pages/AdminCreateTours.jsx'
import AdminEditTours from './pages/AdminEditTours.jsx'
// import AdminEdit from './pages/AdminCreateTours.jsx'

function App() {
  return (
    <Router>
      <div>
        {/* <Navbar /> */}
        <Route exact path="/" component={Home} />
        <Route path="/gallery" component={Gallery} />
        <Route path="/curriculum" component={Curriculum} />
        <Route path="/enrollment" component={Enrollment} />
        <Route path="/enrollmentForm" component={EnrollmentForm} />
        <Route path="/staff" component={Staff} />
        <Route path="/schedule" component={Schedule} />
        <Route path="/covidpolicy" component={Covid} />
        <Route path="/contact" component={Contact} />
        <Route path="/philosophy" component={Philosophy} />
        <Route path="/babysit" component={Babysit} />
        <Route path="/admin/login" component={AdminLogin} />
        <Route path="/admin/home" component={AdminHome} />
        <Route path="/admin/enrollment" component={AdminEnrollment} />
        <Route path="/admin/enrollmentform" component={AdminEnrollmentForm} />
        <Route path="/admin/Messages" component={AdminMessages} />
        <Route path="/admin/createtours" component={AdminCreateTours} />
        <Route path="/admin/edittours" component={AdminEditTours} />
        {/* <Route path="/admin/Edit" component={AdminEdit} /> */}
      </div>
    </Router>
  );
}

export default App;
