import React from 'react';

import './Footer.css';

const Footer = () => {
    return (
        <div className="mt-5 pt-4 footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-xs-12 about-company">
              <h2>Social Media</h2>
              <p className="pr-5 text-white-50">Checkout Our Social Media</p>
              <p><a href="https://www.facebook.com/Just-Kids-Preschool-100147385094865/"><i style={{fontSize:'40px'}} className="fa fa-facebook-square mr-3"></i></a>
              <a href="https://www.instagram.com/justkidspreschoolsf/"><i style={{fontSize:'40px'}} className="fa fa-instagram mr-1"></i></a>
              </p>
            </div>
            <div className="col-lg-3 col-xs-12 links">
              <h4 className="mt-lg-0 mt-sm-3">Links</h4>
                <ul className="m-0 p-0">
                  <li><a href="https://www.littleprodigydaycare.com/">Little Prodigy Daycare</a></li>
                  {/* <li>- <a href="#"></a></li> */}
                </ul>
            </div>
            <div className="col-lg-4 col-xs-12 location">
              <h4 className="mt-lg-0 mt-sm-4">Location</h4>
              <p>5727 Geary Blvd<br/>
                San Francisco, CA 94121
              </p>
              <p className="mb-0"><i className="fa fa-phone mr-3"></i>(415) 830-7898</p>
              <p><i className="fa fa-envelope-o mr-3"></i>JustKidsSF@gmail.com</p>
            </div>
          </div>
          <div className="row">
            <div className="col copyright">
              <p className=""><small className="text-white-50">© 2020. All Rights Reserved.</small></p>
            </div>
          </div>
        </div>
        </div>
    )
}

export default Footer;