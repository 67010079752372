import React from 'react';
import NavbarAdmin from '../components/NavbarAdmin.jsx';
import AdminMessagesList from './AdminMessagesList';
import {auth, db} from './firebaseConfig.js'
const firebase = require("firebase/app");

class AdminMessages extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            messages: null
        }
        this.deleteMessage = this.deleteMessage.bind(this)
    }

    componentDidMount() {
        // this._isMounted = true;
        // auth.onAuthStateChanged(user => {
        //     if (user) {
        //         user.getIdTokenResult().then(idTokenResult => {
        //             if (idTokenResult.claims.admin) {
        //                 if (this._isMounted)
        //                     this.setState({ user });
        //                     db.collection('email').get().then( (querySnapshot) => {
        //                         let data = querySnapshot.docs.map(doc => doc.data())
        //                         return data;
        //                     })
        //                     .then( data => this.setState({messages: data}))
        //                     .catch( err => console.log(err))
        //             } else {
        //                 // alert('You do not have access. Please login as an admin.')
        //                 this.props.history.push('/admin/login')
        //             }
        //         })
        //     } else {
        //         this.props.history.push('/admin/login');
        //     }
        // });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    deleteMessage(id) {
        // db.collection('email').doc('' + id).delete()
        // .catch( err => {
        //     console.log(err)
        // })
        // db.collection('email').get().then( (querySnapshot) => {
        //     let data = querySnapshot.docs.map(doc => doc.data())
        //     return data;
        // })
        // .then( data => this.setState({messages: data}))
        // .catch( err => console.log(err))
    }

    render() {
        if (this.state.user && this.state.messages) {
            return (
                <section>
                        <NavbarAdmin />
                        <div className='container-fluid mt-5'>
                           <div className='row mb-5'>
                                <div className="col-xl-10 col-lg-9 col col-md-8 ml-auto">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="text-muted text-ceter mb-5">Messages</h3>
                                            <table className="table table-striped bg-light text-center">
                                                <thead>
                                                    <tr className="text-muted">
                                                        <th>Created</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Email</th>
                                                        <th>Message</th>
                                                        <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <AdminMessagesList messages={this.state.messages} deleteMessage={this.deleteMessage}/>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                           </div>

                        </div>
                </section>
            )
        } else {
            return <div><NavbarAdmin /></div>
        }
    }
}

export default AdminMessages;