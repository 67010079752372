import React from 'react';
import { withRouter } from 'react-router-dom';
import NavbarAdmin from '../components/NavbarAdmin.jsx';
const firebase = require("firebase/app");

class AdminHome extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this._isMounted = true;
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                user.getIdTokenResult().then(idTokenResult => {
                    if (idTokenResult.claims.admin) {
                        if (this._isMounted)
                            this.setState({ user });
                    } else {
                        // alert('You do not have access. Please login as an admin.')
                        this.props.history.push('/admin/login')
                    }
                })
            } else {
                this.props.history.push('/admin/login');
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if (this.state.user) {
            return (
                <section>
                    <NavbarAdmin />
                    <div className='container-fluid mt-5'>
                        <div className='row mb-5'>
                            <div className="col-xl-10 col-lg-9 col-md-9 ml-auto">
                                <div className="row">
                                    <div className="col-12">
                                        <h3 className="text-muted text-ceter mb-5">Home</h3>
                                        <a href='/admin/enrollment'>
                                            <div className="card text-center">
                                                <div className="card-body">
                                                    <i className="fa fa-user"></i>
                                                    <h5>Enrollment Applications</h5>
                                                </div>
                                            </div>
                                        </a>
                                        <a href='/admin/messages'>
                                            <div className="card text-center">
                                                <div className="card-body">
                                                    <div>
                                                        <i className="fa fa-envelope"></i>
                                                        <h5>Messages</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                        <a href='/admin/createtours'>
                                            <div className="card text-center">
                                                <div className="card-body">
                                                    <i className="fa fa-edit"></i>
                                                    <h5>Create Tours</h5>
                                                </div>
                                            </div>
                                        </a>
                                        <a href='/admin/edittours'>
                                            <div className="card text-center">
                                                <div className="card-body">
                                                    <i className="fa fa-edit"></i>
                                                    <h5>Edit Tours</h5>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            )
        } else {
            return <div><NavbarAdmin /></div>
        }
    }
}

export default withRouter(AdminHome);