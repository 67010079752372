import React from 'react';
import AdminEnrollmentListEntry from './AdminEnrollmentListEntry';

const AdminEnrollmentList = (props) => {
    const applications = props.applications;
    return (
            applications.map( (application, index) => {
                return <AdminEnrollmentListEntry key={index} applicationEntry={application}/>
            })
    )
}

export default AdminEnrollmentList;