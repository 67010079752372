import React from 'react'
import './AdminLogin.css'
import 'firebase/auth';
import 'firebase/firestore';
import NavbarAdmin from '../components/NavbarAdmin.jsx';
import { withRouter } from 'react-router-dom';
const firebase = require("firebase/app");


class AdminLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        }
    }

    componentDidMount() {
        if (this.props.location.pathname === "/admin/login") {
            console.log('admin page')
            firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    // User is signed in.
                    // var isAnonymous = user.isAnonymous;
                    // var uid = user.uid;
                    // ...
                    // user.getIdTokenResult().then(idTokenResult => {
                    //     console.log("login token: ", idTokenResult)
                    //     if (!idTokenResult.claims.admin) {
                    //         firebase.auth().signOut().then(function () {
                    //             // Sign-out successful.
                    //             console.log('Signed out from Anon.')
                    //         }).catch(function (error) {
                    //             // An error happened.
                    //             console.log(error)
                    //         });
                    //     }
                    // })
                } else {
                    // User is signed out.
                    // ...

                }
                // ...
            });

        }
    }

    handleLogin(adminInfo) {
        firebase.auth().signInWithEmailAndPassword(adminInfo.email, adminInfo.password)
            .then(user => {
                console.log('successful login')
                firebase.auth().currentUser.getIdTokenResult().then(idTokenResult => {
                    if (!idTokenResult.claims.admin) {
                        alert('This account does not have access.1')
                        this.setState({ email: '', password: '' })
                    } else {
                        console.log('Welcome ' + idTokenResult.claims.email)
                        this.props.history.push('/admin/home')
                    }
                })
            })
            .catch(function (error) {
                // Handle Errors here.
                // var errorCode = error.code;
                var errorMessage = error.message;
                alert(errorMessage)
                // ...
            });
    }

    // Used to create new admins
    // createAdmin() {
    //     console.log('called')
    //     const addAdminRole = firebase.functions().httpsCallable('addAdminRole');
    //     addAdminRole({ email: "JustKidsSF@gmail.com" }).then(result => {
    //         console.log(result);
    //     });
    // }

    render() {
        return (
            <div>
                <NavbarAdmin />
                <section>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-9 ml-auto">
                                <div className="row pt-5 mt-3 mb-5">
                                    <div className="col-sm-6 p-2 inner-login">
                                        <div className="container-outer-login mt-5 mb-5 container">
                                            <h1>Admin Login</h1>
                                            <div className='login-field'>
                                                <label className='form-title'>Email</label>
                                                <div>
                                                    <input
                                                        type='text'
                                                        id='adminEmail'
                                                        value={this.state.email}
                                                        onChange={(e) => this.setState({ email: e.target.value })}
                                                    ></input>
                                                </div>
                                                <label className='form-title'>Password</label>
                                                <div>
                                                    <input
                                                        type='password'
                                                        id='adminPassword'
                                                        value={this.state.password}
                                                        onChange={(e) => this.setState({ password: e.target.value })}
                                                    ></input>
                                                </div>
                                                <br />
                                                <div>
                                                    <div className="btn btn-info btn-submit mb-3"
                                                        onClick={() => {
                                                            this.handleLogin(this.state);
                                                        }}
                                                    >Login</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

}
export const handleLogout = () => {
    firebase.auth().signOut().then(function() {
        // Sign-out successful.
      }).catch(function(error) {
        // An error happened.
      });
}

export default withRouter(AdminLogin);