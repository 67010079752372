import React from 'react';
import {Modal} from './TourModal'
import './Covid.css';
import Navbar from '../components/Navbar.jsx';
import Footer from '../components/Footer.jsx';

class Covid extends React.Component {
    render() {
        return (
            <div className='whole-page'>
                <Modal/>
                <div className='page-top'>
                    <br></br>
                    <div className='title-anchor'>
                        <a href='/'>
                            <img src={require('../img/logoWhite.png')} alt='Just Kids Preschool Logo' className='center' />
                            <h3 className='page-name text-center' >100% Chinese Immersion Program</h3>
                        </a>

                    </div>

                    <Navbar />
                    <div className='container container-inner mt-5'>
                        <div>
                            <br></br>
                            <h1 className='page-name'>Covid-19 Policy</h1>
                            <br></br>
                            <h3 className='page-name'>The health and well-being of our families and staff are our highest priorities. In
                                the interest of limiting the opportunity for transmission to our vulnerable
                                community, we are taking the following precautionary measures below.</h3>
                            <br></br>
                            <a href="#drop-off">
                                <img className='pic-select center mb-3' src={require('../img/virus.png')} alt='Just Kids Preschool Logo' />
                            </a>
                        </div>

                    </div>
                    <br></br><br></br><br></br><br></br><br></br>

                </div>

                <div className='container'>
                    <div className='philosophy-lower-container mt-5'>
                        <h3 className='topic-header' id="drop-off">Drop Off</h3>
                        <ul>
                            <li className="list-pad">
                                A weekly pre-screening form must be filled out by parents and turned in on Monday.
                            </li>
                            <li className="list-pad">
                                School shoes are required! You can bring in any brand new pair of shoes for your child.
                                Children will change into school shoes upon arrival to keep outside debris out of our
                                classroom.
                            </li>
                            <li className="list-pad">
                                To minimize exposure, please continue to drop off your child at his/her classroom door.
                            </li>
                            <li className="list-pad">
                                Masks should be worn by all parents during drop off. Children will not be received by
                                parents not wearing a mask.
                            </li>
                            <li className="list-pad">
                                Please keep all toys and personal items at home. Children should come to school with a
                                jacket (everyday), bed bag with blanket and sheet (at the beginning of your child’s week),
                                and extra clothes (when necessary).
                            </li>
                            <li className="list-pad">
                                Before your child walks into the classroom, his/her temperature will be checked and
                                recorded by staff. No child will be allowed into the classroom with a temperature of
                                100.4 and over.
                            </li>
                            <li className="list-pad">
                                Please continue to bring your own pen for signing in each morning.
                            </li>
                            <li className="list-pad">
                                Parents should follow the “Social Distancing Protocol” while waiting to drop off or
                                retrieve your child. Please allow six feet of space between families and allow room for
                                the children to be brought out of the classroom.
                            </li>
                        </ul>
                    </div>
                    <div className='philosophy-lower-container mt-5'>
                        <h3 className='topic-header'>Classroom Procedures</h3>
                        <ul>
                            <li className="list-pad">
                                With our new modified operating hours (open at 8 am, closed at 5 pm), so our staff has
                                time to clean and disinfect every day.
                            </li>
                            <li className="list-pad">
                                Children and staff will follow frequent handwashing protocol with soap and water
                                throughout the day. Hand sanitizer will be provided at drop off and pick up time.
                            </li>
                            <li className="list-pad">
                                We will continue to model and instruct proper coughing and sneezing etiquette (into our ˝elbow).
                            </li>
                            <li className="list-pad">
                                To the best of our ability we will follow the “Social Distancing” protocol by splitting the
                                children into small groups as much as possible and offering a variety of activities to
                                encourage the children to play in different parts of the classroom.
                            </li>
                            <li className="list-pad">
                                We will provide children with more outdoor time throughout the day (weather and healthy air quality permitting).
                            </li>
                            <li className="list-pad">
                                Staff will wear face masks while with the children. Children are required to bring a face
                                mask to school. Please clearly label your child’s mask in a clear bag (your child may
                                store his/her mask while not in use).
                            </li>
                            <li className="list-pad">
                                Staff will provide each child a water bottle each day. Please leave your water bottles at home.
                            </li>
                        </ul>
                    </div>
                    <div className='philosophy-lower-container mt-5'>
                        <h3 className='topic-header'>Naptime</h3>
                        <ul>
                            <li className="list-pad">
                                All bedding must come clean in your child’s labeled bag each week (on the first day
                                based on your child’s schedule).
                            </li>
                            <li className="list-pad">
                                We will try our best to maintain the 6 feet rule at naptime and nap children in head to toe pattern.
                            </li>
                        </ul>
                    </div>
                    <div className='philosophy-lower-container mt-5'>
                        <h3 className='topic-header'>Pick Up</h3>
                        <ul>
                            <li className="list-pad">
                                Children will be brought to the classroom door for pick up.
                            </li>
                            <li className="list-pad">
                                Please maintain the ‘Social Distancing” protocol during pick up time.
                            </li>
                            <li className="list-pad">
                                Please check your child for symptoms daily. We recommend a temperature check at
                                home before coming to school. Children should stay home if temperature is 100.4 and over.
                            </li>
                            <li className="list-pad">
                                Your child will not be admitted if they have a fever of 100.4 and over or are showing any
                                symptoms of illness which include but not limited to coughing, runny nose, bodily
                                discharge of any kind, and rash of any kind.
                            </li>
                            <li className="list-pad">
                                Should you or any household member have any of the following symptoms, we ask you
                                to remain out that day and notify Just Kids Preschool.
                                <ul>
                                    <li className="list-pad">
                                        Fever of 100.4 F or higher, now or in the preceding 72 hours (or would have,
                                        but have used fever reducing medicine)
                                    </li>
                                    <li className="list-pad">
                                        Cough
                                    </li>
                                    <li className="list-pad">
                                        Sore Throat
                                    </li>
                                    <li className="list-pad">
                                        Muscle Aches
                                    </li>
                                    <li className="list-pad">
                                        Difficulty Breathing
                                    </li>
                                    <li className="list-pad">
                                        New Loss of Taste or Smell
                                    </li>
                                </ul>
                            </li>
                            <li className="list-pad">
                                Your child and household members must remain out of Just Kids Preschool if any member of
                                your household has (or has been in close contact with anyone who has):
                                <ul>
                                    <li className="list-pad">
                                        A suspected or confirmed case of COVID-19 (for example – close contact at
                                        school, work, religious service, social gathering); or
                                    </li>
                                    <li className="list-pad">
                                        Traveled: internationally; or domestically, 14 days after the last potential
                                        exposure, your household may return provided these three things have happened:
                                        <ol>
                                            <li>
                                                At least 10 days have passed since any household member first experienced symptoms;
                                            </li>
                                            <li>
                                                Symptoms have improved for any household member that experienced symptoms (for example, cough or shortness of breath has improved);
                                            </li>
                                            <li>
                                                The household has been fever-free for at least 72 hours without the use of fever-reducing medicines.
                                            </li>
                                        </ol>
                                    </li>
                                </ul>
                            </li>
                            <li className="list-pad">
                                Please note, depending on the circumstances we may require you to obtain medical
                                clearance before returning to Just Kids Preschool.
                            </li>
                            <li className="list-pad">
                                You must notify us if anyone in your household has or has come in contact with someone
                                who has the symptoms or tested positive for COVID-19. Please notify us as soon as
                                possible via email justkidssf@gmail.com. This will help protect your child’s classroom
                                from the spread of an outbreak.
                            </li>
                            <li className="list-pad">
                                The household will be required to remain out of the center for 14 days unless medical
                                clearance is provided by a physician indicating that the presenting symptoms are
                                associated with a known non-COVID-19 illness. The physician’s note must not be from
                                a family member. If your child becomes sick while at school, he/she will be isolated from
                                the group and will need to be picked up within the hour.
                            </li>
                            <li className="list-pad">
                                In addition to regular and thorough cleaning of classrooms, surfaces and toys, we teach
                                children good hand-washing practices and ensure that children and staff take important
                                precautions against the spread of germs. You can work with your children to practice
                                some of these same steps at home:
                            </li>
                            <ul>
                                <li className="list-pad">
                                    Wash hands often with soap and water for at least 20 seconds.
                                </li>
                                <li className="list-pad">
                                    Sneeze or cough into your elbow; or cover mouth and nose with a tissue.
                                </li>
                                <li className="list-pad">
                                    Immediately discarding tissues after use.
                                </li>
                                <li className="list-pad">
                                    Avoid touching eyes, nose, and mouth with unwashed hands.
                                </li>
                                <li className="list-pad">
                                    Avoid kissing, hugging, and sharing cups or eating utensils with people who are ill.
                                </li>
                                <li className="list-pad">
                                    Disinfecting frequently touched surfaces, especially if someone is sick.
                                </li>
                            </ul>
                        </ul>
                    </div>
                    <div className='philosophy-lower-container mt-5'>
                        <h3 className='topic-header'>Just Kids Preschool Reimbursement Policy</h3>
                        <p>
                            Unfortunately, Just Kids Preschool does not provide families with tuition reimbursement. For example:
                        </p>
                        <ul>
                            <li>
                                Vacation
                            </li>
                            <li>
                                Sick days
                            </li>
                            <li>
                                Holidays
                            </li>
                            <li>
                                Covid-19 closures (due to illness, exposure with someone who
                                has Coronavirus, traveling, and/or classroom exposure)
                            </li>
                        </ul>
                        <p>
                            In the event California has another statewide shut down (March 2020),
                            Just Kids Preschool will require half of your child’s monthly tuition to
                            guarantee a saved enrollment spot for your child, once it is safe to openagain.
                        </p>
                    </div>
                </div>

                {/* <div className='cards-container mt-5 pt-4'>
                    <div className="card text-white bg-primary mb-3 card1">
                        <div className="card-header">Rayne</div>
                        <div className="card-body">
                            <p className="card-text">I'm Evil >:)</p>
                        </div>
                    </div>
                    
                    <div className="card text-white bg-secondary mb-3 card1">
                        <div className="card-header">Kaylee</div>
                        <div className="card-body">
                            <p className="card-text">I like to whine :(</p>
                        </div>
                    </div>

                    <div className="card text-white bg-success mb-3 card1">
                        <div className="card-header">Zayne</div>
                        <div className="card-body">
                            <p className="card-text">Give me Food!</p>
                        </div>
                    </div>

                </div> */}

                <div>

                </div>
                <Footer />
            </div>
        )
    }
}

export default Covid;