import React from 'react';
import AdminEditToursOptionsEntry from './AdminEditToursOptionsEntry';

const AdminEditToursOptions = (props) => {
    const options = props.options;
    return (
            options.map( (options, index) => {
                return <AdminEditToursOptionsEntry key={index} index={index} optionEntry={options} onClickDate={props.onClickDate}/>
            })
    )
}

export default AdminEditToursOptions;