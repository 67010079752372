import React from 'react';
import './TourModal.css';

export const Modal = () => {
    return (
        <div className='modal-wrapper'>
            {/* <div className='modal-header'>
                <h5>Want to visit?</h5>
            </div> */}
            <div className='modal-body'>
                <a className='btn btn-secondary mt-4 mb-4 pic-select' href='https://directory.legup.care/childcare/just-kids-preschool-san-francisco' target="blank">Click here to book a tour!</a>
            </div>
            
        </div>
    )
}