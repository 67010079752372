import React from 'react';
import NavbarAdmin from '../components/NavbarAdmin.jsx';
import AdminEnrollmentList from './AdminEnrollmentList';
import {auth, db} from './firebaseConfig.js'
const firebase = require("firebase/app");


class AdminEnrollment extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            applications: null
        }
    }

    componentDidMount() {
        this._isMounted = true;
        // auth.onAuthStateChanged(user => {
        //     if (user) {
        //         user.getIdTokenResult().then(idTokenResult => {
        //             if (idTokenResult.claims.admin) {
        //                 if (this._isMounted)
        //                     this.setState({ user });
        //                     db.collection('users').get().then( (querySnapshot) => {
        //                         let data = querySnapshot.docs.map(doc => doc.data())
        //                         return data;
        //                     })
        //                     .then( data => this.setState({applications: data}))
        //                     .catch( err => console.log(err))
        //             } else {
        //                 // alert('You do not have access. Please login as an admin.')
        //                 this.props.history.push('/admin/login')
        //             }
        //         })
        //     } else {
        //         this.props.history.push('/admin/login');
        //     }
        // });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if (this.state.user && this.state.applications) {
            return (
                <section>
                        <NavbarAdmin />
                        <div className='container-fluid mt-5'>
                           <div className='row mb-5'>
                                <div className="col-xl-10 col-lg-9 col col-md-8 ml-auto">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="text-muted text-ceter mb-5">Enrollment Forms</h3>
                                            <table className="table table-striped bg-light text-center">
                                                <thead>
                                                    <tr className="text-muted">
                                                        <th>#</th>
                                                        <th>Student Name</th>
                                                        <th>Parent Name</th>
                                                        <th>Email</th>
                                                        <th>Enroll</th>
                                                        <th>Archive</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <AdminEnrollmentList applications={this.state.applications}/>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                           </div>

                        </div>
                </section>
            )
        } else {
            return <div><NavbarAdmin /></div>
        }
    }
}

export default AdminEnrollment;