import React from 'react';
import {Modal} from './TourModal'
import './Gallery.css';
import Navbar from '../components/Navbar.jsx';
import Footer from '../components/Footer.jsx';

class Gallery extends React.Component {
    render() {
        return (
            <div className='whole-page'>
                <Modal/>
                <div className='page-top'>
                    <br></br>
                    <div className='title-anchor'>
                        <a href='/'>
                            <img src={require('../img/logoWhite.png')} alt='Just Kids Preschool Logo' className='center' />
                            <h3 className='page-name text-center' >100% Chinese Immersion Program</h3>
                        </a>

                    </div>
                    <Navbar />
                        <br></br>
                        {/* <h2>Gallery</h2> */}
                        <br></br>
                        <div className='container'>
                            <div id="carouselIndicators" className="carousel slide" data-ride="carousel">
                                <ol className="carousel-indicators">
                                    <li data-target="#carouselIndicators" data-slide-to="0" className="active"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="1"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="2"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="3"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="4"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="5"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="6"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="7"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="8"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="9"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="10"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="11"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="12"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="13"></li>
                                </ol>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p00.jpg')} alt="First slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p0.jpg')} alt="Second slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p1.jpg')} alt="Third slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p2.jpg')} alt="Fourth slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p3.jpg')} alt="Fifth slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p4.jpg')} alt="Sixth slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img portrait" src={require('../img/gallery/p5.jpg')} alt="Seventh slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img portrait" src={require('../img/gallery/p6.jpg')} alt="Eighth slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img portrait" src={require('../img/gallery/p7.jpg')} alt="Nineth slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img portrait" src={require('../img/gallery/p8.jpg')} alt="Tenth slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p9.jpg')} alt="11th slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img portrait" src={require('../img/gallery/p10.jpg')} alt="12th slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img portrait" src={require('../img/gallery/p11.jpg')} alt="13th slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img portrait" src={require('../img/gallery/p12.jpg')} alt="14th slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p13.jpg')} alt="15th slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img portrait" src={require('../img/gallery/p14.jpg')} alt="16th slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img portrait" src={require('../img/gallery/p15.jpg')} alt="17th slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p16.jpg')} alt="18th slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p17.jpg')} alt="19th slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p18.jpg')} alt="20th slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p19.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p20.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p21.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p22.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p23.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p24.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p25.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p26.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p27.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p28.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p29.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p30.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p31.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p32.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p33.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p34.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p35.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p36.jpg')} alt="slide"></img>
                                    </div>
                                </div>
                                <a className="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
                                    <span className='fa fa-chevron-circle-left' aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
                                    <span className='fa fa-chevron-circle-right' aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>

                        </div>
                        
                        
                        <br></br><br></br><br></br><br></br><br></br>
                    </div>

                <Footer />
            </div>
        )
    }
}

export default Gallery;