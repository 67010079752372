import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light">
            <div className="d-md-flex d-block flex-row mx-md-auto nav-font">
                {/* <NavLink activeClassName="navbar__link--active" className="navbar__link navbar-brand" to="#">Genius Fightclub</NavLink> */}
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                    <li className="nav-item active">
                        <NavLink exact activeClassName="navbar__link--active" className="navbar__link nav-link btn btn-primary nav-font" to="/">Home</NavLink>
                    </li>
                    {/* <li className="nav-item">
                        <NavLink activeClassName="navbar__link--active" className="navbar__link nav-link btn btn-success nav-font" to="/Philosophy">Philosophy</NavLink>
                    </li> */}
                    {/* <li className="nav-item">
                        <NavLink activeClassName="navbar__link--active" className="navbar__link nav-link btn btn-info nav-font" to="/Curriculum">Curriculum</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink activeClassName="navbar__link--active" className="navbar__link nav-link btn btn-success nav-font" to="/Staff">Staff</NavLink>
                    </li> */}
                    <li className="nav-item">
                        <NavLink activeClassName="navbar__link--active" className="navbar__link nav-link btn btn-primary nav-font" to="/Enrollment">Enrollment</NavLink>
                    </li>
                    {/* <li className="nav-item">
                        <NavLink activeClassName="navbar__link--active" className="navbar__link nav-link btn btn-primary nav-font" to="/Gallery">Gallery</NavLink>
                    </li> */}
                    <li className="nav-item">
                        <NavLink activeClassName="navbar__link--active" className="navbar__link nav-link btn btn-primary nav-font" to="/Schedule">Schedule</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink activeClassName="navbar__link--active" className="navbar__link nav-link btn btn-primary nav-font" to="/CovidPolicy">Covid-19 Policy</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink activeClassName="navbar__link--active" className="navbar__link nav-link btn btn-primary nav-font" to="/Contact">Contact Us</NavLink>
                    </li>
                    {/* <li className="nav-item dropdown ">
                        <NavLink activeClassName="navbar__link--active" className="navbar__link nav-link btn btn-primary dropdown-toggle nav-font" to="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Other
                        </NavLink>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <NavLink activeClassName="navbar__link--active" className="navbar__link dropdown-item" to="#">Another action</NavLink>
                        <NavLink activeClassName="navbar__link--active" className="navbar__link dropdown-item" to="#">Something else here</NavLink>
                        </div>
                    </li> */}
                    </ul>
                </div>
                </div>
        </nav>
    )
}

export default Navbar;