import React from 'react';
import { withRouter } from 'react-router-dom';
import NavbarAdmin from '../components/NavbarAdmin.jsx';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import useDB from './useDB.jsx'
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import './AdminCreateTours.css'
import {auth, db} from './firebaseConfig.js'
const firebase = require("firebase/app");

class AdminCreateTours extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            today: null,
            dates: [],
            datesP: [],
            selectedDate: [],
            selectedDateString: "",
            newTour: {
                year: null,
                month: null,
                day: null,
                times: {
                    count: 0,
                    "7:45": { tour: false, unbooked: true, timeString: "7:45 - 8:15am" },
                    "8:30": { tour: false, unbooked: true, timeString: "8:30 - 9:00am" },
                    "9:15": { tour: false, unbooked: true, timeString: "9:15 - 9:45am" },
                    "10:00": { tour: false, unbooked: true, timeString: "10:00 - 10:30am" },
                    "10:45": { tour: false, unbooked: true, timeString: "10:45 - 11:15am" },
                    "11:30": { tour: false, unbooked: true, timeString: "11:30 - 12:00pm" },
                    "12:15": { tour: false, unbooked: true, timeString: "12:15 - 12:45pm" },
                    "1:00": { tour: false, unbooked: true, timeString: "1:00 - 1:30pm" },
                    "1:45": { tour: false, unbooked: true, timeString: "1:45 - 2:15pm" },
                    "2:30": { tour: false, unbooked: true, timeString: "2:30 - 3:00pm" },
                    "3:15": { tour: false, unbooked: true, timeString: "3:15 - 3:45pm" },
                    "4:00": { tour: false, unbooked: true, timeString: "4:00 - 4:30pm" },
                    "4:45": { tour: false, unbooked: true, timeString: "4:45 - 5:15pm" },
                    "5:30": { tour: false, unbooked: true, timeString: "5:30 - 6:00pm" },
                    "6:30": { tour: false, unbooked: true, timeString: "6:30 - 7:00pm" },
                }
            },
            times: {
                count: 0,
                "7:45": { tour: false, unbooked: true, timeString: "7:45 - 8:15am" },
                "8:30": { tour: false, unbooked: true, timeString: "8:30 - 9:00am" },
                "9:15": { tour: false, unbooked: true, timeString: "9:15 - 9:45am" },
                "10:00": { tour: false, unbooked: true, timeString: "10:00 - 10:30am" },
                "10:45": { tour: false, unbooked: true, timeString: "10:45 - 11:15am" },
                "11:30": { tour: false, unbooked: true, timeString: "11:30 - 12:00pm" },
                "12:15": { tour: false, unbooked: true, timeString: "12:15 - 12:45pm" },
                "1:00": { tour: false, unbooked: true, timeString: "1:00 - 1:30pm" },
                "1:45": { tour: false, unbooked: true, timeString: "1:45 - 2:15pm" },
                "2:30": { tour: false, unbooked: true, timeString: "2:30 - 3:00pm" },
                "3:15": { tour: false, unbooked: true, timeString: "3:15 - 3:45pm" },
                "4:00": { tour: false, unbooked: true, timeString: "4:00 - 4:30pm" },
                "4:45": { tour: false, unbooked: true, timeString: "4:45 - 5:15pm" },
                "5:30": { tour: false, unbooked: true, timeString: "5:30 - 6:00pm" },
                "6:30": { tour: false, unbooked: true, timeString: "6:30 - 7:00pm" },
            }

        }
    }

    componentDidMount() {
        this._isMounted = true;
        auth.onAuthStateChanged(user => {
            if (user) {
                useDB.getData(db, true, this.setInitialState)
            } else {
                useDB.logGuest();
            }
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setInitialState = (data) => {
        this.setState({ today: data.today })
        this.setState({ dates: data.dates })
        this.setState({ datesP: data.datesP })
        this.setState({ user: data.user });
        this.handleCheckbox("10:00")
        this.handleCheckbox("10:45")
        this.handleCheckbox("11:30")
        this.handleCheckbox("12:15")
        this.handleCheckbox("1:00")
        this.handleCheckbox("1:45")
    }

    onClickDate = (dates) => {
        console.log(dates)
        if (dates.length > this.state.dates.length) {
            let selectedDate = dates[dates.length - 1];
            let selectedDateString = selectedDate.month + '/' + selectedDate.day + '/' + selectedDate.year;
            this.setState({ selectedDate });
            this.setState({ selectedDateString })
        } else {
            this.setState({ selectedDate: "" });
            this.setState({ selectedDateString: "" })
        }
    }

    handleSubmitNewTourDate = () => {
        //Check to see if required info is filled
        if (this.state.times.count !== 0 && this.state.selectedDate.length !== 0) {
            let docRef = null;
            let docRef2 = null;
            let tourInfo = this.state.selectedDate;
            tourInfo.times = this.state.times;
            tourInfo.dateString = this.state.selectedDateString;
            let fullSchedule = this.state.dates;
            fullSchedule.sort(function (a, b) {
                var aa = a.dateString.split('/').reverse().join(),
                    bb = b.dateString.split('/').reverse().join();
                return aa < bb ? -1 : (aa > bb ? 1 : 0);
            });
            fullSchedule.push(tourInfo)
            docRef2.update({
                fullSchedule
            })
                .then(() => {
                    docRef.update({
                        fullSchedule
                    })
                })
                .catch((err) => {
                    console.log('Error getting documents', err);
                });
            alert('Successfully updated.')
            this.props.history.push('/admin/createtours')
        } else {
            alert('Please select a date and at least one appointment time')
        }
    }

    handleCheckbox = (time) => {
        //update counter
        let newSchedule = this.state.times;
        newSchedule[time].tour = !newSchedule[time].tour;
        if (!this.state.times[time]) {
            newSchedule.count = this.state.times.count - 1;
        } else {
            newSchedule.count = this.state.times.count + 1;
        }
        this.setState({ times: newSchedule })
    }

    handleSetNormalTimes = () => {

    }

    render() {
        if (this.state.user) {
            return (
                <section>
                    <NavbarAdmin />
                    <div className='mt-5'>
                        <section-flex>
                            <nav-filler></nav-filler>
                            <article>
                                <h4>
                                    Select Date
                                </h4>
                                <Calendar
                                    value={this.state.dates}
                                    onChange={this.onClickDate}
                                    calendarClassName="responsive-calendar"
                                    minimumDate={utils().getToday()}
                                    shouldHighlightWeekends
                                />
                            </article>
                            <article>
                                <div className='revert'>
                                    <h4>
                                        Create Appointments
                                    </h4>
                                    <div className='appointment-text mt-2'>
                                        <input value={this.state.selectedDateString} readOnly="readonly">
                                        </input>
                                    </div>
                                    <div>
                                        <input className='mr-2' type="checkbox" onClick={() => this.handleCheckbox("7:45")} />
                                        <label > 7:45 - 8:15am</label><br />
                                        <input className='mr-2' type="checkbox" onClick={() => this.handleCheckbox("8:30")} />
                                        <label > 8:30 - 9:00am</label><br />
                                        <input className='mr-2' type="checkbox" onClick={() => this.handleCheckbox("9:15")} />
                                        <label > 9:15 - 9:45am</label><br />
                                        <input className='mr-2' type="checkbox" onClick={() => this.handleCheckbox("10:00")} defaultChecked/>
                                        <label > 10:00 - 10:30am</label><br />
                                        <input className='mr-2' type="checkbox" onClick={() => this.handleCheckbox("10:45")} defaultChecked/>
                                        <label > 10:45 - 11:15am</label><br />
                                        <input className='mr-2' type="checkbox" onClick={() => this.handleCheckbox("11:30")} defaultChecked/>
                                        <label > 11:30 - 12:00pm</label><br />
                                        <input className='mr-2' type="checkbox" onClick={() => this.handleCheckbox("12:15")} defaultChecked/>
                                        <label > 12:15 - 12:45pm</label><br />
                                        <input className='mr-2' type="checkbox" onClick={() => this.handleCheckbox("1:00")} defaultChecked/>
                                        <label > 1:00 - 1:30pm</label><br />
                                        <input className='mr-2' type="checkbox" onClick={() => this.handleCheckbox("1:45")} defaultChecked/>
                                        <label > 1:45 - 2:15pm</label><br />
                                        <input className='mr-2' type="checkbox" onClick={() => this.handleCheckbox("2:30")} />
                                        <label > 2:30 - 3:00pm</label><br />
                                        <input className='mr-2' type="checkbox" onClick={() => this.handleCheckbox("3:15")} />
                                        <label > 3:15 - 3:45pm</label><br />
                                        <input className='mr-2' type="checkbox" onClick={() => this.handleCheckbox("4:00")} />
                                        <label > 4:00 - 4:30pm</label><br />
                                        <input className='mr-2' type="checkbox" onClick={() => this.handleCheckbox("4:45")} />
                                        <label > 4:45 - 5:15pm</label><br />
                                        <input className='mr-2' type="checkbox" onClick={() => this.handleCheckbox("5:30")} />
                                        <label > 5:30 - 6:00pm</label><br />
                                        <input className='mr-2' type="checkbox" onClick={() => this.handleCheckbox("6:30")} />
                                        <label > 6:30 - 7:00pm</label><br />
                                    </div>
                                    <div className="btn btn-info btn-submit mb-3 mr-5"
                                        onClick={() => {
                                            this.handleSubmitNewTourDate(this.state);
                                        }}
                                    >Submit Tours</div>
                                </div>

                            </article>
                        </section-flex>


                    </div>
                </section>
            )
        } else {
            return <div><NavbarAdmin /></div>
        }
    }
}

export default withRouter(AdminCreateTours);