import React from 'react';
import {Modal} from './TourModal'
import './Schedule.css';
import Navbar from '../components/Navbar.jsx';
import Footer from '../components/Footer.jsx';

class Schedule extends React.Component {
    render() {
        return (
            <div className='whole-page'>
                <Modal/>
                <div className='page-top'>
                    <br></br>
                    <div className='title-anchor'>
                        <a href='/'>
                            <img src={require('../img/logoWhite.png')} alt='Just Kids Preschool Logo' className='center' />
                            <h3 className='page-name text-center' >100% Chinese Immersion Program</h3>
                        </a>

                    </div>

                    <Navbar />
                    <div className='container container-inner-schedule mt-5'>
                        <div>
                            <br></br>
                            <h1 className='page-name'> Daily Schedule and Holidays</h1>
                            <br></br>
                            <p>
                                Please take a look at our daily schedule and which days Just Kids Preschool will be 
                                closed.
                            </p>
                            <br></br>
                            <a className="mb-3" href="#schedule">
                                <img src={require('../img/storyTime1.jpg')} alt='Just Kids Preschool Schedule' className='pic-select center title-img' />
                            </a>
                            <br></br>
                        </div>

                    </div>
                    <br></br><br></br><br></br><br></br><br></br>

                </div>

                <div className='container'>
                    <div className='philosophy-lower-container mt-5'>
                        <h3 className='topic-header' id='schedule'>Schedule</h3>
                        <table className="table table-striped bg-light text-center">
                            <thead>
                                <tr className="text-muted">
                                    <th>Activity</th>
                                    <th>Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="text-muted">
                                    <th>Drop-off/welcoming activities</th>
                                    <th>7:30AM - 9:00AM</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>Circle Time</th>
                                    <th>9:00AM - 9:30AM</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>Snack Time</th>
                                    <th>9:30AM - 9:45AM</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>Story Time</th>
                                    <th>9:45AM - 10:00AM</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>Outdoor/Indoor activities. Split into two groups.</th>
                                    <th>10:00AM - 12:00PM</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>Lunch</th>
                                    <th>12:00PM - 12:30PM</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>Part-time pick-up/drop-offs</th>
                                    <th>12:30PM</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>Nap/Rest Time</th>
                                    <th>12:30PM - 2:30PM</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>Snack Time</th>
                                    <th>2:30PM - 3:00PM</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>Circle Time</th>
                                    <th>3:00PM - 3:30PM</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>Outdoor/Indoor Activities</th>
                                    <th>3:30PM - 5:00PM</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>Indoor Activities, Quiet Activities</th>
                                    <th>5:00PM - 6:00PM</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className='philosophy-lower-container mt-5'>
                        <h3 className='topic-header'>Holidays and Breaks 2024</h3>
                        <table className="table table-striped bg-light text-center">
                            <thead>
                                <tr className="text-muted">
                                    <th>Holiday</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr className="text-muted">
                                    <th>Dec 24 , 2020 - Jan 1, 2021</th>
                                    <th>Winter Break</th>
                                </tr> */}
                                <tr className="text-muted">
                                    <th>Martin Lurther King Jr. Day</th>
                                    <th>Jan 15, 2024</th>
                                </tr>
                                {/* <tr className="text-muted">
                                    <th>Lunar New Year</th>
                                    <th>Feb 1, 2022</th>
                                </tr> */}
                                <tr className="text-muted">
                                    <th>President's Day</th>
                                    <th>Feb 19, 2024</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>Memorial Day</th>
                                    <th>May 27, 2024</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>Summer Break</th>
                                    <th>Jun 24, 2024 - Jul 5, 2024</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>Labor Day</th>
                                    <th>Sept 2, 2024</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>Indigenous Peoples' Day</th>
                                    <th>Oct 14, 2024</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>Veterans Day</th>
                                    <th>Nov 11, 2024</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>Thanksgiving</th>
                                    <th>Nov 27, 2024 - Nov 29, 2024</th>
                                </tr>
                                <tr className="text-muted">
                                    <th>Winter Break</th>
                                    <th>Dec 23, 2024 - Jan 3, 2025</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

                {/* <div className='cards-container mt-5 pt-4'>
                    <div className="card text-white bg-primary mb-3 card1">
                        <div className="card-header">Rayne</div>
                        <div className="card-body">
                            <p className="card-text">I'm Evil >:)</p>
                        </div>
                    </div>
                    
                    <div className="card text-white bg-secondary mb-3 card1">
                        <div className="card-header">Kaylee</div>
                        <div className="card-body">
                            <p className="card-text">I like to whine :(</p>
                        </div>
                    </div>

                    <div className="card text-white bg-success mb-3 card1">
                        <div className="card-header">Zayne</div>
                        <div className="card-body">
                            <p className="card-text">Give me Food!</p>
                        </div>
                    </div>

                </div> */}

                <div>

                </div>
                <Footer />
            </div>
        )
    }
}

export default Schedule;