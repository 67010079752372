import { utils } from "react-modern-calendar-datepicker";
import 'firebase/auth';
import 'firebase/firestore';
import {auth, db} from './firebaseConfig.js'
// import { getDefaultSettings } from 'http2';
const firebase = require("firebase/app");
require("firebase/functions");

// Required for side-effects
require("firebase/firestore");

//returns current data
export function getCurrentTimes(db, dateIndex) {
    return new Promise(async (res, rej) =>
        db.collection('tours').get().then(querySnapshot => {
            let data = querySnapshot.docs.map(doc => doc.data())
            return data[1].fullSchedule[dateIndex].times
        })
            .then(result => {
                res(result)
            })
    )
}

//Updates database to dates only today and after
export function updateDBToCurrentDate(db) {
    let docRef = db.collection('tours').doc("dates")
    let docRef2 = db.collection('tours').doc("public")
    firebase.auth().onAuthStateChanged(user => {
        if (user) {
            user.getIdTokenResult().then(result => {
                db.collection('tours').get().then(querySnapshot => {
                    let data = querySnapshot.docs.map(doc => doc.data())
                    return data
                })
                    .then(data => {
                        let today = utils().getToday();
                        let curTours = [];
                        let curToursP = []

                        //update and sort current dates
                        data[0].fullSchedule.forEach((c, i) => {
                            if (c.year > today.year) {
                                curTours.push(c)
                            } else if (c.year === today.year) {
                                if (c.month > today.month) {
                                    curTours.push(c)
                                } else if (c.month === today.month) {
                                    if (c.day >= today.day) {
                                        curTours.push(c)
                                    }
                                }
                            }
                        })
                        curTours.sort(function (a, b) {
                            var aa = a.dateString.split('/'),
                                bb = b.dateString.split('/');
                            return bb[3] - aa[3] || aa[0] - bb[0] || aa[1] - bb[1];
                        });

                        //update and sort public
                        data[1].fullSchedule.forEach((c, i) => {
                            if (c.year > today.year) {
                                curToursP.push(c)
                            } else if (c.year === today.year) {
                                if (c.month > today.month) {
                                    curToursP.push(c)
                                } else if (c.month === today.month) {
                                    if (c.day >= today.day) {
                                        curToursP.push(c)
                                    }
                                }
                            }
                        })
                        curToursP.sort(function (a, b) {
                            var aa = a.dateString.split('/'),
                                bb = b.dateString.split('/');
                            return bb[3] - aa[3] || aa[0] - bb[0] || aa[1] - bb[1];
                        });
                        docRef.set({
                            fullSchedule: curTours
                        })
                        docRef2.set({
                            fullSchedule: curToursP
                        })
                    })
            })
        }
    })
}

//creates a tour appointment and updates database
export function makeBooking(db, selectedDateIndex, selectedTime, tourName, tourEmail, tourPhone) {
    return new Promise(async (res, rej) => {
        let docRef = db.collection('tours').doc("dates")
        let docRef2 = db.collection('tours').doc("public")
        firebase.auth().onAuthStateChanged(user => {
            let fullSchedule;
            let fullScheduleP;
            if (user) {
                user.getIdTokenResult().then(idTokenResult => {
                    db.collection('tours').get().then((querySnapshot) => {
                        let data = querySnapshot.docs.map(doc => doc.data())
                        fullSchedule = data[0].fullSchedule;
                        fullScheduleP = data[1].fullSchedule;
                    })
                        .then(data => {
                            fullSchedule[selectedDateIndex].times[selectedTime].unbooked = false;
                            fullSchedule[selectedDateIndex].times.count = fullSchedule[selectedDateIndex].times.count - 1;
                            fullSchedule[selectedDateIndex].times[selectedTime].tourName = tourName;
                            fullSchedule[selectedDateIndex].times[selectedTime].tourEmail = tourEmail;
                            fullSchedule[selectedDateIndex].times[selectedTime].tourPhone = tourPhone;
                            fullScheduleP[selectedDateIndex].times[selectedTime].unbooked = false;
                            fullScheduleP[selectedDateIndex].times.count = fullScheduleP[selectedDateIndex].times.count - 1;

                        })
                        .then(data => {
                            docRef.update({
                                fullSchedule
                            })
                            docRef2.update({
                                fullSchedule: fullScheduleP
                            })
                        })
                        .then(() => {
                            let bookingCreated = firebase.functions().httpsCallable('bookingCreated');
                            bookingCreated({ info: fullSchedule[selectedDateIndex].times[selectedTime], date: fullSchedule[selectedDateIndex].dateString });
                        })
                        .then(() => {
                            console.log('successfully sent!')
                            res('sent')
                        })
                        .catch((err) => {
                            console.log('Error getting documents', err);
                        });
                    // alert('Successfully booked a tour.')
                })
            }
        })
    })
}

//gets data if admin access
export function getData(db, getAll, callback) {
    console.log('getting data')
    firebase.auth().onAuthStateChanged(user => {
        if (user) {
            user.getIdTokenResult().then(idTokenResult => {
                if (idTokenResult.claims.admin) {
                    if (this._isMounted)
                        this.setState({ user });
                    db.collection('tours').get().then((querySnapshot) => {
                        let data = querySnapshot.docs.map(doc => doc.data())
                        return data;
                    })
                        .then(data => {
                            let today = utils().getToday();
                            let dates = [];
                            let datesP = [];
                            data[0].fullSchedule.forEach((c, i) => {
                                if (c.year > today.year) {
                                    dates.push(c)
                                } else if (c.year === today.year) {
                                    if (c.month > today.month) {
                                        dates.push(c)
                                    } else if (c.month === today.month) {
                                        if (c.day >= today.day) {
                                            dates.push(c)
                                        }
                                    }
                                }
                            })
                            data[1].fullSchedule.forEach((c, i) => {
                                if (c.year > today.year) {
                                    datesP.push(c)
                                } else if (c.year === today.year) {
                                    if (c.month > today.month) {
                                        datesP.push(c)
                                    } else if (c.month === today.month) {
                                        if (c.day >= today.day) {
                                            datesP.push(c)
                                        }
                                    }
                                }
                            })
                            callback({ today, dates, datesP, user });
                            // return 'lol'
                            // return {today, dates, datesP}
                        })
                        .catch(err => console.log(err))
                } else {
                    alert('You do not have access. Please login as an admin.')
                    // this.props.history.push('/admin/login')
                }
            })
        } else {
            this.props.history.push('/admin/login');
        }
    });

}

export function logGuest() {
    auth.signInWithEmailAndPassword('guest@justkidspreschool.org', 'test1234');
}

export default { makeBooking, getData, logGuest, updateDBToCurrentDate, getCurrentTimes };