import React from 'react';
import AdminMessagesListEntry from './AdminMessagesListEntry';

const AdminMessagesList = (props) => {
    const messages = props.messages;
    return (
            messages.map( (message, index) => {
                return <AdminMessagesListEntry key={index} messageEntry={message} deleteMessage={props.deleteMessage}/>
            })
    )
}

export default AdminMessagesList;