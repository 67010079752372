import React from 'react';
import { withRouter } from 'react-router-dom';
import NavbarAdmin from '../components/NavbarAdmin.jsx';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import AdminEditToursOptions from './AdminEditToursOptions';
import './AdminEditTours.css'
import {auth, db} from './firebaseConfig.js'
const firebase = require("firebase/app");

class AdminEditTours extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            today: null,
            dates: [],
            datesP: [],
            selectedDate: [],
            selectedDateP: [],
            selectedDateString: "",
            times: {
                count: 0,
                "7:45": { tour: false, unbooked: true, timeString: "7:45 - 8:15am" },
                "8:30": { tour: false, unbooked: true, timeString: "8:30 - 9:00am" },
                "9:15": { tour: false, unbooked: true, timeString: "9:15 - 9:45am" },
                "10:00": { tour: false, unbooked: true, timeString: "10:00 - 10:30am" },
                "10:45": { tour: false, unbooked: true, timeString: "10:45 - 11:15am" },
                "11:30": { tour: false, unbooked: true, timeString: "11:30 - 12:00pm" },
                "12:15": { tour: false, unbooked: true, timeString: "12:15 - 12:45pm" },
                "1:00": { tour: false, unbooked: true, timeString: "1:00 - 1:30pm" },
                "1:45": { tour: false, unbooked: true, timeString: "1:45 - 2:15pm" },
                "2:30": { tour: false, unbooked: true, timeString: "2:30 - 3:00pm" },
                "3:15": { tour: false, unbooked: true, timeString: "3:15 - 3:45pm" },
                "4:00": { tour: false, unbooked: true, timeString: "4:00 - 4:30pm" },
                "4:45": { tour: false, unbooked: true, timeString: "4:45 - 5:15pm" },
                "5:30": { tour: false, unbooked: true, timeString: "5:30 - 6:00pm" },
                "6:30": { tour: false, unbooked: true, timeString: "6:30 - 7:00pm" },
            },
            timesP: {},

        }
    }

    componentDidMount() {
        this._isMounted = true;
        // auth.onAuthStateChanged(user => {
        //     if (user) {
        //         user.getIdTokenResult().then(idTokenResult => {
        //             if (idTokenResult.claims.admin) {
        //                 if (this._isMounted)
        //                     this.setState({ user });
        //                 db.collection('tours').get().then((querySnapshot) => {
        //                     let data = querySnapshot.docs.map(doc => doc.data())
        //                     return data;
        //                 })
        //                     // .then(data => data.fullSchedule)
        //                     .then(data => {
        //                         let today = utils().getToday();
        //                         let curTours = [];
        //                         let curToursP = [];
        //                         data[0].fullSchedule.forEach((c, i) => {
        //                             if(c.year > today.year) {
        //                                 curTours.push(c)
        //                             } else if (c.year === today.year) {
        //                                 if(c.month > today.month) {
        //                                     curTours.push(c)
        //                                 } else if (c.month === today.month) {
        //                                     if(c.day >= today.day) {
        //                                         curTours.push(c)
        //                                     }
        //                                 }
        //                             }
        //                         })
        //                         curTours.sort(function (a, b) {
        //                             var aa = a.dateString.split('/'),
        //                                 bb = b.dateString.split('/');
        //                             return bb[3] - aa[3] || aa[0] - bb[0] || aa[1] - bb[1];
        //                         });
        //                         data[1].fullSchedule.forEach((c, i) => {
        //                             if(c.year > today.year) {
        //                                 curToursP.push(c)
        //                             } else if (c.year === today.year) {
        //                                 if(c.month > today.month) {
        //                                     curToursP.push(c)
        //                                 } else if (c.month === today.month) {
        //                                     if(c.day >= today.day) {
        //                                         curToursP.push(c)
        //                                     }
        //                                 }
        //                             }
        //                         })
        //                         curToursP.sort(function (a, b) {
        //                             var aa = a.dateString.split('/'),
        //                                 bb = b.dateString.split('/');
        //                             return bb[3] - aa[3] || aa[0] - bb[0] || aa[1] - bb[1];
        //                         });
        //                         this.setState({ today })
        //                         this.setState({dates: curTours})
        //                         this.setState({datesP: curToursP})
        //                         // return curTours;
        //                     })
        //                     // .then(dates => this.setState({ dates }))
        //                     .catch(err => console.log(err))
        //             } else {
        //                 // alert('You do not have access. Please login as an admin.')
        //                 this.props.history.push('/admin/login')
        //             }
        //         })
        //     } else {
        //         this.props.history.push('/admin/login');
        //     }
        // });

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onClickDate = (selectedDateIndex) => {
        if (selectedDateIndex < 0) {
            this.setState({ selectedDateIndex: [] })
            return;
        }
        this.setState({selectedDateIndex})
        this.setState({ selectedDate: this.state.dates[selectedDateIndex] }, () => {
            this.setState({ selectedDateString: this.state.dates[selectedDateIndex].dateString })
            this.setState({ times: this.state.selectedDate.times })
        })
        this.setState({ selectedDateP: this.state.datesP[selectedDateIndex] }, () => {
            this.setState({ timesP: this.state.selectedDateP.times })
        })
    }

    sortDates = (dates) => {
        dates.sort(function (a, b) {
            var aa = a.dateString.split('/').reverse().join(),
                bb = b.dateString.split('/').reverse().join();
            return aa < bb ? -1 : (aa > bb ? 1 : 0);
        });
    }

    handleEditTourDate = () => {
        //Check to see if required info is filled
        // if (this.state.times.count !== 0 && this.state.selectedDate.length !== 0) {
        //     let docRef = db.collection('tours').doc("dates")
        //     let docRef2 = db.collection('tours').doc("public")
        //     //need to set current selected dates/times to date
        //     let fullSchedule = this.state.dates;
        //     let fullScheduleP = this.state.datesP
        //     docRef.update({
        //         fullSchedule
        //     })
        //     .then( () => {
        //         fullScheduleP[this.selectedDateIndex] = this.state.selectedDateP;
        //     })
        //     .then( () => console.log(fullScheduleP))
        //     .then( () => {
        //         docRef2.update({
        //             fullSchedule: fullScheduleP
        //         })
        //     })
        //     .catch((err) => {
        //         console.log('Error getting documents', err);
        //     });
        //     alert('Successfully updated.')
        //     this.props.history.push('/admin/home')
        // } else {
        //     alert('Please select a date and at least one appointment time')
        // }
    }

    handleDeletedCurrentDate = () => {
        // let docRef = db.collection('tours').doc("dates")
        // let docRef2 = db.collection('tours').doc("public")
        // let datesCopy = [...this.state.dates];
        // let datesCopyP = [...this.state.datesP];
        // for (let i = 0; i < datesCopy.length; i++) {
        //     if (datesCopy[i].dateString === this.state.selectedDateString) {
        //         datesCopy.splice(i, 1);
        //         datesCopyP.splice(i, 1);
        //         break;
        //     }
        // }
        // this.setState({ dates: datesCopy, datesP: datesCopyP }, () => {
        //     let fullSchedule = this.state.dates;
        //     let fullScheduleP = this.state.datesP;
        //     docRef.update({
        //         fullSchedule
        //     })
        //     .then( () => {
        //         docRef2.update({
        //             fullSchedule: fullScheduleP
        //         })
        //     })
        //         .catch((err) => {
        //             console.log('Error getting documents', err);
        //         });

        //     alert('Successfully updated.')
        //     this.props.history.push('/admin/home')
        // })
    }

    handleCheckbox = (time) => {
        //update counter
        let newSchedule = { ...this.state.times };
        let newScheduleP = { ...this.state.timesP };
        newSchedule[time].tour = !newSchedule[time].tour;
        newScheduleP[time].tour = newSchedule[time].tour;
        if (!newSchedule[time].tour) {
            newSchedule.count = this.state.times.count - 1;
            newScheduleP.count = this.state.timesP.count - 1;
        } else {
            newSchedule.count = this.state.times.count + 1;
            newScheduleP.count = this.state.timesP.count + 1;
        }
        this.setState({ times: newSchedule })
        this.setState({ timesP: newScheduleP })

        let copyDates = [...this.state.dates];
        let copyDatesP = [...this.state.datesP]
        copyDates[this.state.selectedDateIndex].times = newSchedule;
        copyDatesP[this.state.selectedDateIndex].times = newScheduleP;

        this.setState({dates: copyDates});
        this.setState({dates: copyDatesP});

    }

    checkBooked = (time) => {
        if (!this.state.selectedDate.times[time].unbooked) {
            return <div className="booked-info-box">
                <label>Booked: {time}</label>
                <div>Name: {this.state.selectedDate.times[time].tourName}</div>
                <div>Email: {this.state.selectedDate.times[time].tourEmail}</div>
                <div>Number: {this.state.selectedDate.times[time].tourPhone}</div>
            </div>
        }
    }

    render() {
        let tourInfo;
        if (this.state.selectedDate.length !== 0) {
            tourInfo =
                <div>
                    <input className='mr-2' type="checkbox" checked={this.state.times["7:45"].tour} onChange={() => this.handleCheckbox("7:45")} />
                    <label > 7:45 - 8:15am</label><br />
                    {this.checkBooked("7:45")}
                    <input className='mr-2' type="checkbox" checked={this.state.times["8:30"].tour} onChange={() => this.handleCheckbox("8:30")} />
                    <label > 8:30 - 9:00am</label><br />
                    {this.checkBooked("8:30")}
                    <input className='mr-2' type="checkbox" checked={this.state.times["9:15"].tour} onChange={() => this.handleCheckbox("9:15")} />
                    <label > 9:15 - 9:45am</label><br />
                    {this.checkBooked("9:15")}
                    <input className='mr-2' type="checkbox" checked={this.state.times["10:00"].tour} onChange={() => this.handleCheckbox("10:00")} />
                    <label > 10:00 - 10:30am </label><br />
                    {this.checkBooked("10:00")}
                    <input className='mr-2' type="checkbox" checked={this.state.times["10:45"].tour} onChange={() => this.handleCheckbox("10:45")} />
                    <label > 10:45 - 11:15am </label><br />
                    {this.checkBooked("10:45")}
                    <input className='mr-2' type="checkbox" checked={this.state.times["11:30"].tour} onChange={() => this.handleCheckbox("11:30")} />
                    <label > 11:30 - 12:00pm</label><br />
                    {this.checkBooked("11:30")}
                    <input className='mr-2' type="checkbox" checked={this.state.times["12:15"].tour} onChange={() => this.handleCheckbox("12:15")} />
                    <label > 12:15 - 12:45pm</label><br />
                    {this.checkBooked("12:15")}
                    <input className='mr-2' type="checkbox" checked={this.state.times["1:00"].tour} onChange={() => this.handleCheckbox("1:00")} />
                    <label > 1:00 - 1:30pm</label><br />
                    {this.checkBooked("1:00")}
                    <input className='mr-2' type="checkbox" checked={this.state.times["1:45"].tour} onChange={() => this.handleCheckbox("1:45")} />
                    <label > 1:45 - 2:15pm</label><br />
                    {this.checkBooked("1:45")}
                    <input className='mr-2' type="checkbox" checked={this.state.times["2:30"].tour} onChange={() => this.handleCheckbox("2:30")} />
                    <label > 2:30 - 3:00pm</label><br />
                    {this.checkBooked("2:30")}
                    <input className='mr-2' type="checkbox" checked={this.state.times["3:15"].tour} onChange={() => this.handleCheckbox("3:15")} />
                    <label > 3:15 - 3:45pm</label><br />
                    {this.checkBooked("3:15")}
                    <input className='mr-2' type="checkbox" checked={this.state.times["4:00"].tour} onChange={() => this.handleCheckbox("4:00")} />
                    <label > 4:00 - 4:30pm</label><br />
                    {this.checkBooked("4:00")}
                    <input className='mr-2' type="checkbox" checked={this.state.times["4:45"].tour} onChange={() => this.handleCheckbox("4:45")} />
                    <label > 4:45 - 5:15pm</label><br />
                    {this.checkBooked("4:45")}
                    <input className='mr-2' type="checkbox" checked={this.state.times["5:30"].tour} onChange={() => this.handleCheckbox("5:30")} />
                    <label > 5:30 - 6:00pm</label><br />
                    {this.checkBooked("5:30")}
                    <input className='mr-2' type="checkbox" checked={this.state.times["6:30"].tour} onChange={() => this.handleCheckbox("6:30")} />
                    <label > 6:30 - 7:00pm</label><br />
                    {this.checkBooked("6:30")}
                </div>
        } else {
            tourInfo = <div></div>
        }
        if (this.state.user) {
            return (
                <section>
                    <NavbarAdmin />
                    <div className='mt-5'>
                        <section-flex>
                            <nav-filler></nav-filler>
                            <article>
                                <Calendar
                                    value={this.state.dates}
                                    // onChange={this.onClickDate}
                                    calendarClassName="responsive-calendar"
                                    minimumDate={utils().getToday()}
                                    shouldHighlightWeekends
                                />
                            </article>
                            <article>
                                <div className='revert'>
                                    <h4>
                                        Select Date for Editing
                                    </h4>
                                    <select onChange={(e) => this.onClickDate(e.target.value)}>
                                        <option value={-1} >-</option>
                                        <AdminEditToursOptions options={this.state.dates} onClickDate={this.onClickDate} />
                                    </select>
                                    <br></br>
                                    {tourInfo}
                                    <div className="btn btn-info btn-submit mb-3 mr-5"
                                        onClick={() => {
                                            this.handleEditTourDate();
                                        }}
                                    >Submit Changes</div>
                                    <div className="btn btn-warning btn-submit mb-3 mr-5"
                                        onClick={() => {
                                            this.handleDeletedCurrentDate();
                                        }}
                                    >Delete Selected Date</div>
                                </div>
                            </article>
                        </section-flex>


                    </div>
                </section>
            )
        } else {
            return <div><NavbarAdmin /></div>
        }
    }
}

export default withRouter(AdminEditTours);