import React from 'react';
import './EnrollmentForm.css';
import Navbar from '../components/Navbar.jsx';
import Footer from '../components/Footer.jsx';
import 'firebase/auth';
import 'firebase/firestore';
import {auth, db} from './firebaseConfig.js'

class EnrollmentForm extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            studentStartDateMM: '',
            studentStartDateDD: '',
            studentStartDateYYYY: '',
            studentFirstName: '',
            studentLastName: '',
            studentCallName: '',
            studentBDMM: '',
            studentBDDD: '',
            studentBDYYYY: '',
            studentGender: '',
            studentAgeGroup: '',
            studentPottyTrained: '',
            studentAllergies: '',
            p1FirstName: '',
            p1LastName: '',
            p1RelationshipToChild: '',
            p1HomeAddress: '',
            p1City: '',
            p1State: '',
            p1Zip: '',
            p1Email: '',
            p1Cell: '',
            p1Home: '',
            p2FirstName: '',
            p2LastName: '',
            p2RelationshipToChild: '',
            p2HomeAddress: '',
            p2City: '',
            p2State: '',
            p2Zip: '',
            p2Email: '',
            p2Cell: '',
            p2Home: '',
            moreInfo: '',
            homeLanguage: '',
        }
    }

    handleSubmit = () => {

        if (this.filledRequired()) {
            auth.signInWithEmailAndPassword('guest@justkidspreschool.org', 'test1234')
                .then(() => {
                    db.collection('count').doc('userCount').get()
                        .then((doc) => {
                            this.setState({ formID: doc.data().count }, () => {
                                let docRef = db.collection('users').doc(this.state.formID.toString())
                                let info = this.state;
                                docRef.set({
                                    info
                                })
                                    .catch((err) => {
                                        console.log('Error getting documents', err);
                                    });

                                db.collection('count').doc('userCount').set({
                                    count: this.state.formID + 1
                                })
                                    .catch((err) => {
                                        console.log('Error getting documents', err);
                                    });

                                alert('Your form has successfully been submitted.')
                                window.scrollTo(0, 0)
                                this.props.history.push('/')

                            })
                        })
                        .catch((err) => {
                            console.log('Error getting documents', err);
                        });
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    filledRequired = () => {
        let info = this.state;
        if (info.studentStartDateDD.length < 2 || info.studentStartDateMM.length < 2 || info.studentStartDateYYYY.length < 4 ||
            info.studentBDDD.length < 2 || info.studentBDMM.length < 2 || info.studentBDYYYY.length < 4) {
            alert('Invalid Date. Please check your dates.')
            return false;
        }
        if (info.studentFirstName.length < 1 || info.studentLastName.length < 1) {
            alert('Please enter child\'s first and last name.')
            return false;
        }
        if (info.studentGender.length < 1) {
            alert('Please select child\'s gender.')
            return false;
        }
        if (info.studentAgeGroup.length < 1) {
            alert('Please select child\'s age group.')
            return false;
        }
        if (info.studentPottyTrained.length < 1) {
            alert('Please select if your child is potty trained.')
            return false;
        }
        if (info.p1FirstName.length < 1 || info.p1LastName.length < 1) {
            alert('Please enter parent/guardian\'s first and last name.')
            return false;
        }
        if (info.p1RelationshipToChild.length < 1) {
            alert('Please enter parent/guardian\'s relation to child.')
            return false;
        }
        if (info.p1HomeAddress.length < 1 || info.p1City.length < 1 || info.p1State.length < 1 || info.p1Zip.length < 1) {
            alert('Please parent/guardian\'s full address.')
            return false;
        }
        if (info.p1Email.length < 1) {
            alert('Please enter parent/guardian\'s email.')
            return false;
        }
        if (info.p1Cell.length < 1) {
            alert('Please enter parent/guardian\'s mobile phone number.')
            return false;
        }

        return true;
    }

    render() {
        return (
            <div>
                <div className='topBG'>
                    <br></br>
                    <div className='title-anchor'>
                        <a href='/'>
                            <img src={require('../img/logo.png')} alt='Just Kids Preschool Logo' className='center' />
                        </a>

                    </div>
                    <Navbar />
                    <div className='container container-inner-form mt-5 mb-5'>
                        <br></br>
                        <div className='section-header'> Enrollment Application Form</div>
                        <form className='form-style'>
                            <div id='studentInfo'>
                                <div className='form-line' id='studentStartDate'>
                                    <label className='form-title'>Starting Date *</label>
                                    <div className='field-element'>
                                        <input
                                            className={'inline-2'}
                                            type='text'
                                            id='studentStartDateMM'
                                            maxLength='2'
                                            placeholder='MM'
                                            value={this.state.studentStartDateMM}
                                            onChange={(e) => this.setState({ studentStartDateMM: e.target.value })}
                                        />
                                        <input
                                            className={'inline-2'}
                                            type='text'
                                            id='studentStartDateDD'
                                            maxLength='2'
                                            placeholder='DD'
                                            value={this.state.studentStartDateDD}
                                            onChange={(e) => this.setState({ studentStartDateDD: e.target.value })}
                                        />
                                        <input
                                            className={'inline-4'}
                                            type='text'
                                            id='studentStartDateYYYY'
                                            maxLength='4'
                                            placeholder='YYYY'
                                            value={this.state.studentStartDateYYYY}
                                            onChange={(e) => this.setState({ studentStartDateYYYY: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <label className='section-header'>Student Information</label><br />
                                <div className='form-line' id='childName'>
                                    <label className='form-title'>Child's Full Name *</label><br />
                                    <div className='field-element'>
                                        <input
                                            className={'inline'}
                                            type='text'
                                            id='studentFirstName'
                                            placeholder='First Name'
                                            value={this.state.studentFirstName}
                                            onChange={(e) => this.setState({ studentFirstName: e.target.value })}
                                        />
                                        <input
                                            className={'inline lastName'}
                                            type='text'
                                            id='studentLastName'
                                            placeholder='Last Name'
                                            value={this.state.studentLastName}
                                            onChange={(e) => this.setState({ studentLastName: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className='form-line' id='studentCallNname'>
                                    <label className='form-title'>What would you like your child to be called in class?</label>
                                    <div className='field-element'>
                                        <input
                                            className={'inline'}
                                            type='text'
                                            id='studentCallName'
                                            placeholder=''
                                            value={this.state.studentCallName}
                                            onChange={(e) => this.setState({ studentCallName: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className='form-line' id='studentBD'>
                                    <label className='form-title'>Child's Birthday *</label>
                                    <div className='field-element'>
                                        <input
                                            className={'inline-2'}
                                            type='text'
                                            id='studentBDMM'
                                            maxLength='2'
                                            placeholder='MM'
                                            value={this.state.studentBDMM}
                                            onChange={(e) => this.setState({ studentBDMM: e.target.value })}
                                        />
                                        <input
                                            className={'inline-2'}
                                            type='text'
                                            id='studentBDDD'
                                            maxLength='2'
                                            placeholder='DD'
                                            value={this.state.studentBDDD}
                                            onChange={(e) => this.setState({ studentBDDD: e.target.value })}
                                        />
                                        <input
                                            className={'inline-4'}
                                            type='text'
                                            id='studentBDYYYY'
                                            maxLength='4'
                                            placeholder='YYYY'
                                            value={this.state.studentBDYYYY}
                                            onChange={(e) => this.setState({ studentBDYYYY: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className='form-line' id='studentGender'>
                                    <label className='form-title'>Child's Gender *</label>
                                    <div className='field-element radio-gender' onChange={(e) => this.setState({ studentGender: e.target.id })}>
                                        <input className='radio-selection' type='radio' id='male' name='gender' /> Male  <br />
                                        <input className='radio-selection' type='radio' id='female' name='gender' /> Female  <br />
                                        <input className='radio-selection' type='radio' id='other' name='gender' /> Other  <br />
                                    </div>
                                </div>
                                <div className='form-line' id='studentAgeGroup'>
                                    <label className='form-title'>Age Group *</label>
                                    <div className='field-element radio-gender' onChange={(e) => this.setState({ studentAgeGroup: e.target.id })}>
                                        <input className='radio-selection' type='radio' id='2-3' name='age' /> 2 - 3  <br />
                                        <input className='radio-selection' type='radio' id='4-5' name='age' /> 4 - 5  <br />
                                    </div>
                                </div>
                                <div className='form-line' id='pottyTrained'>
                                    <label className='form-title'>Is your child potty trained? *</label>
                                    <div className='field-element radio-gender' onChange={(e) => this.setState({ studentPottyTrained: e.target.id })}>
                                        <input className='radio-selection' type='radio' id='yes' name='trained' /> Yes  <br />
                                        <input className='radio-selection' type='radio' id='no' name='trained' /> No  <br />
                                    </div>
                                </div>
                                <div className='form-line' id='studentAllergies'>
                                    <label className='form-title'>What allergies do your child have?</label>
                                    <div className='field-element'>
                                        <input
                                            className={'inline'}
                                            type='text'
                                            id='studentAllergiesInput'
                                            placeholder='Ex: Peatnuts'
                                            value={this.state.studentAllergies}
                                            onChange={(e) => this.setState({ studentAllergies: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <hr />

                            <div id='parentInfo mb-5'>
                                <label className='section-header'>Parent/Guardian Information</label><br />
                                <div className='form-line' id='p1Name'>
                                    <label className='form-title'>Parent/Guardian's Name *</label><br />
                                    <div className='field-element'>
                                        <input
                                            className={'inline'}
                                            type='text'
                                            id='p1FirstName'
                                            placeholder='First Name'
                                            value={this.state.p1FirstName}
                                            onChange={(e) => this.setState({ p1FirstName: e.target.value })}
                                        />
                                        <input
                                            className={'inline lastName'}
                                            type='text'
                                            id='p1LastName'
                                            placeholder='Last Name'
                                            value={this.state.p1LastName}
                                            onChange={(e) => this.setState({ p1LastName: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className='form-line' id='p1RelationshipToChild'>
                                    <label className='form-title'>Relation to Child *</label>
                                    <div className='field-element'>
                                        <input
                                            className={'inline'}
                                            type='text'
                                            id='p1RelationshipToChild'
                                            placeholder=''
                                            value={this.state.p1RelationshipToChild}
                                            onChange={(e) => this.setState({ p1RelationshipToChild: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div id='p1HomeAddress'>
                                    <label className='form-title'>Home Address *</label>
                                    <div className='field-element'>
                                        <input
                                            className={'inline'}
                                            style={{ width: '100%' }}
                                            type='text'
                                            id='p1HomeAddress'
                                            placeholder='Address'
                                            value={this.state.p1HomeAddress}
                                            onChange={(e) => this.setState({ p1HomeAddress: e.target.value })}
                                        />
                                    </div>
                                    <div id='city-state-line'>
                                        <div className='field-element' id='city-state'>
                                            <input
                                                className={'inline'}
                                                style={{ width: '70%' }}
                                                type='text'
                                                id='p1City'
                                                placeholder='City'
                                                value={this.state.p1City}
                                                onChange={(e) => this.setState({ p1City: e.target.value })}
                                            />
                                            <input
                                                className={'inline'}
                                                style={{ width: '30%', marginLeft: '10px' }}
                                                type='text'
                                                id='p1State'
                                                placeholder='State/Province'
                                                value={this.state.p1State}
                                                onChange={(e) => this.setState({ p1State: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='field-element' >
                                        <input
                                            className={'inline'}
                                            style={{ width: '30%' }}
                                            type='text'
                                            id='p1Zip'
                                            placeholder='Zip/Postal Code'
                                            value={this.state.p1Zip}
                                            onChange={(e) => this.setState({ p1Zip: e.target.value })}
                                        />
                                    </div>


                                </div>
                                <div className='form-line' id='p1Email'>
                                    <label className='form-title'>Email *</label>
                                    <div className='field-element'>
                                        <input
                                            className={'inline'}
                                            type='text'
                                            id='p1Email'
                                            placeholder='Email'
                                            value={this.state.p1Email}
                                            onChange={(e) => this.setState({ p1Email: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className='field-element'>
                                    <div className='form-line' id='p1Cell'>
                                        <label className='form-title'>Mobile Number *</label>
                                        <div className='field-element'>
                                            <input
                                                className={'inline'}
                                                style={{ width: '100%' }}
                                                type='text'
                                                id='p1Cell'
                                                placeholder='###-###-####'
                                                value={this.state.p1Cell}
                                                onChange={(e) => this.setState({ p1Cell: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='form-line' id='p1Home' style={{ marginLeft: '30px' }}>
                                        <label className='form-title'>Home Number</label>
                                        <div className='field-element'>
                                            <input
                                                className={'inline'}
                                                style={{ width: '100%' }}
                                                type='text'
                                                id='p1Home'
                                                placeholder='###-###-####'
                                                value={this.state.p1Home}
                                                onChange={(e) => this.setState({ p1Home: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <hr />

                            <div id='parentInfo2 mb-5 mt-5'>
                                <div className='form-line mt-2' id='p2Name'>
                                    <label className='form-title'>Parent/Guardian's Name 2</label><br />
                                    <div className='field-element'>
                                        <input
                                            className={'inline'}
                                            type='text'
                                            id='p2FirstName'
                                            placeholder='First Name'
                                            value={this.state.p2FirstName}
                                            onChange={(e) => this.setState({ p2FirstName: e.target.value })}
                                        />
                                        <input
                                            className={'inline lastName'}
                                            type='text'
                                            id='p2LastName'
                                            placeholder='Last Name'
                                            value={this.state.p2LastName}
                                            onChange={(e) => this.setState({ p2LastName: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className='form-line' id='p2RelationshipToChild'>
                                    <label className='form-title'>Relation to Child 2</label>
                                    <div className='field-element'>
                                        <input
                                            className={'inline'}
                                            type='text'
                                            id='p2RelationshipToChild'
                                            placeholder=''
                                            value={this.state.p2RelationshipToChild}
                                            onChange={(e) => this.setState({ p2RelationshipToChild: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className='form-line' id='p2HomeAddress'>
                                    <label className='form-title'>Home Address 2 </label>
                                    <div className='field-element'>
                                        <input
                                            className={'inline'}
                                            style={{ width: '100%' }}
                                            type='text'
                                            id='p2HomeAddress'
                                            placeholder='Address'
                                            value={this.state.p2HomeAddress}
                                            onChange={(e) => this.setState({ p2HomeAddress: e.target.value })}
                                        />
                                    </div>
                                    <div id='city-state-line'>
                                        <div className='field-element' id='city-state'>
                                            <input
                                                className={'inline'}
                                                style={{ width: '70%' }}
                                                type='text'
                                                id='p2City'
                                                placeholder='City'
                                                value={this.state.p2City}
                                                onChange={(e) => this.setState({ p2City: e.target.value })}
                                            />
                                            <input
                                                className={'inline'}
                                                style={{ width: '30%', marginLeft: '10px' }}
                                                type='text'
                                                id='p2State'
                                                placeholder='State/Province'
                                                value={this.state.p2State}
                                                onChange={(e) => this.setState({ p2State: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='field-element' >
                                        <input
                                            className={'inline'}
                                            style={{ width: '30%' }}
                                            type='text'
                                            id='p2Zip'
                                            placeholder='Zip/Postal Code'
                                            value={this.state.p2Zip}
                                            onChange={(e) => this.setState({ p2Zip: e.target.value })}
                                        />
                                    </div>


                                </div>
                                <div className='form-line' id='p2Email'>
                                    <label className='form-title'>Email</label>
                                    <div className='field-element form-line'>
                                        <input
                                            className={'inline'}
                                            type='text'
                                            id='p2Email'
                                            placeholder='Email'
                                            value={this.state.p2Email}
                                            onChange={(e) => this.setState({ p2Email: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className='field-element form-line'>
                                    <div className='form-line' id='p2Cell'>
                                        <label className='form-title'>Mobile Number</label>
                                        <div className='field-element form-line'>
                                            <input
                                                className={'inline'}
                                                style={{ width: '100%' }}
                                                type='text'
                                                id='p2Cell'
                                                placeholder='###-###-####'
                                                value={this.state.p2Cell}
                                                onChange={(e) => this.setState({ p2Cell: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='form-line' id='p2Home' style={{ marginLeft: '30px' }}>
                                        <label className='form-title'>Home Number</label>
                                        <div className='field-element'>
                                            <input
                                                className={'inline'}
                                                style={{ width: '100%' }}
                                                type='text'
                                                id='p2Home'
                                                placeholder='###-###-####'
                                                value={this.state.p2Home}
                                                onChange={(e) => this.setState({ p2Home: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <hr />
                            <div id='moreInfo'>
                                <label className='section-header'>Additional Information</label>
                                <div className='form-line mb-3' id='childName'>
                                    <textarea
                                        name="Text1"
                                        style={{ width: "80%" }}
                                        cols="40"
                                        rows="5"
                                        onChange={(e) => this.setState({ moreInfo: e.target.value })}
                                    ></textarea>
                                </div>
                            </div>
                            <div style={{ fontSize: '.8em' }}>
                                <div><p>Please remit $100 Application Fee via Check or Money Order and drop off or send to: </p></div>
                                <div>
                                    Just Kids Preschool<br />
                                    5727 Geary Blvd<br />
                                    San Francisco, CA 94121
                            </div><br />
                                <div>
                                    <p>
                                        Your application is complete once the completed form and the application fee have been received.
                            </p>
                                </div>
                            </div>
                            <div>
                                <div className="btn btn-info btn-submit mb-3"
                                    onClick={() => {
                                        this.handleSubmit(this.state);
                                    }}
                                >Submit</div>
                            </div>
                            <br></br><br></br>
                        </form>

                    </div>


                    <br></br><br></br><br></br><br></br><br></br>
                </div>
                <Footer />
            </div >
        )
    }
}

// const EnrollmentForm = withRouter(EnrollmentForm);
export default EnrollmentForm;