import React from 'react';
import {Modal} from './TourModal'
import './Curriculum.css';
import Navbar from '../components/Navbar.jsx';
import Footer from '../components/Footer.jsx';

class Curriculum extends React.Component {
    render() {
        return (
            <div className='whole-page'>
                <Modal/>
                <div className='page-top'>
                    <br></br>
                    <div className='title-anchor'>
                        <a href='/'>
                            <img src={require('../img/logoWhite.png')} alt='Just Kids Preschool Logo' className='center' />
                            <h3 className='page-name text-center' >100% Chinese Immersion Program</h3>
                        </a>

                    </div>
                    <Navbar />
                    <div className='container container-inner mt-5'>
                        <br></br>

                        <h2 className='page-name'>Curriculum</h2>
                        <br></br>
                        <p>​Contact us about parties, feedbacks, updates, and questions.</p>
                        <p>If you are looking to book a party, please call us.
                            It is a lot faster, and your chances of getting the
                            day and time you want is higher. </p>
                        <br></br>
                    </div>
                    <br></br><br></br><br></br><br></br><br></br>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Curriculum;