import React from 'react';
import './Home.css';
import Navbar from '../components/Navbar.jsx';
import Footer from '../components/Footer.jsx';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import {auth, db} from './firebaseConfig.js'
import AdminEditToursOptions from './AdminEditToursOptions';
import HomeToursTime from './HomeToursTime'
import useDB from './useDB.jsx'
import {Modal} from './modalBooking'
import { getFirestore } from "firebase/firestore";
import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";

// const app = initializeApp(firebaseConfig);

// const db = getFirestore();

// const auth = getAuth(app);

class Home extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            today: null,
            dates: [],
            selectedDate: [],
            selectedDateIndex: 0,
            selectedTime: 'invalid',
            selectedDateString: "",
            times: {
                count: 0,
                "7:45": { tour: false, unbooked: true, timeString: "7:45 - 8:15am" },
                "8:30": { tour: false, unbooked: true, timeString: "8:30 - 9:00am" },
                "9:15": { tour: false, unbooked: true, timeString: "9:15 - 9:45am" },
                "10:00": { tour: false, unbooked: true, timeString: "10:00 - 10:30am" },
                "10:45": { tour: false, unbooked: true, timeString: "10:45 - 11:15am" },
                "11:30": { tour: false, unbooked: true, timeString: "11:30 - 12:00pm" },
                "12:15": { tour: false, unbooked: true, timeString: "12:15 - 12:45pm" },
                "1:00": { tour: false, unbooked: true, timeString: "1:00 - 1:30pm" },
                "1:45": { tour: false, unbooked: true, timeString: "1:45 - 2:15pm" },
                "2:30": { tour: false, unbooked: true, timeString: "2:30 - 3:00pm" },
                "3:15": { tour: false, unbooked: true, timeString: "3:15 - 3:45pm" },
                "4:00": { tour: false, unbooked: true, timeString: "4:00 - 4:30pm" },
                "4:45": { tour: false, unbooked: true, timeString: "4:45 - 5:15pm" },
                "5:30": { tour: false, unbooked: true, timeString: "5:30 - 6:00pm" },
                "6:30": { tour: false, unbooked: true, timeString: "6:30 - 7:00pm" },
            },
            tourName: "",
            tourEmail: "",
            tourPhone: "",
            showModal: false,
        }

        this.onClickDate = this.onClickDate.bind(this);
        this.onClickTime = this.onClickTime.bind(this);
        this.handleSubmitTour = this.handleSubmitTour.bind(this);


    }

    componentDidMount() {
        this._isMounted = true;
        // checks to see if there is a user logged in
        auth.onAuthStateChanged(user => {
            if (user) {
                user.getIdTokenResult().then(idTokenResult => {
                        if (this._isMounted)
                            this.setState({ user });
                        db.collection('tours').get().then((querySnapshot) => {
                            let data = querySnapshot.docs.map(doc => doc.data())
                            return data[1];
                        })
                            .then(data => data.fullSchedule)
                            .then(data => {
                                let today = utils().getToday();
                                let curTours = [];
                                data.forEach((c, i) => {
                                    if(c.year > today.year) {
                                        curTours.push(c)
                                    } else if (c.year === today.year) {
                                        if(c.month > today.month) {
                                            curTours.push(c)
                                        } else if (c.month === today.month) {
                                            if(c.day >= today.day) {
                                                curTours.push(c)
                                            }
                                        }
                                    }
                                })
                                curTours.sort(function (a, b) {
                                    var aa = a.dateString.split('/'),
                                        bb = b.dateString.split('/');
                                    return bb[3] - aa[3] || aa[0] - bb[0] || aa[1] - bb[1];
                                });
                                this.setState({ today })
                                return curTours;
                            })
                            .then(dates => this.setState({ dates }))
                            .then( () => {
                                if(this.state.dates.length > 0) {
                                    this.setState({selectedDate: this.state.dates[0]})
                                }
                            })
                            .then( () => {
                                if(this.state.dates.length > 0) {
                                    this.setState({times: this.state.dates[0].times})
                                }
                            })
                            .then( () => {
                                useDB.updateDBToCurrentDate(db);
                            })
                            .catch(err => console.log(err))

                })
            } else {
                useDB.logGuest();
            }
        });

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onClickDate = (selectedDateIndex) => {
        if(selectedDateIndex < 0) {
            this.setState({ selectedDateIndex: [] })
            return;
        }
        this.setState({selectedDateIndex})
        this.setState({ selectedDate: this.state.dates[selectedDateIndex] }, () => {
            this.setState({selectedDateString: this.state.dates[selectedDateIndex].dateString})
            this.setState({ times: this.state.selectedDate.times })
            this.setState({selectedTime: "invalid"})
        })
    }

    onClickTime = (selectedTime) => {
        this.setState({selectedTime})
    }

    hideModal = () => {
        new Promise((res, rej) => {
            setTimeout( () => {
                this.setState({showModal: false})
            }, 5000)
        })
    }

    setNewTimes = (times) => {
        this.setState({times: times})
    }

    handleSubmitTour = () => {
        //Check to see if required info is filled
        if (this.state.tourName.length !== 0 && this.state.tourEmail.length !== 0 && this.state.tourPhone.length !== 0 && this.state.selectedDate.length !== 0 && this.state.selectedTime !== 'invalid') {
            useDB.makeBooking(db, this.state.selectedDateIndex, this.state.selectedTime, this.state.tourName, this.state.tourEmail, this.state.tourPhone)
            .then( () => {
                useDB.getCurrentTimes(db, this.state.selectedDateIndex)
                .then(res => this.setState({times: res}) )
            })
            this.setState({showModal: true})
            this.hideModal();
            this.setState({ selectedTime: '', tourName: '', tourEmail: '', tourPhone: ''})
        } else {
            alert('Please fill out all information.')
        }

    }

    render() {
        let tourInfo;
        if (this.state.selectedDate.length !== 0) {
            tourInfo =
            <select onChange={(e) => this.onClickTime(e.target.value)}>
                <option value='invalid'>-</option>
                <HomeToursTime times={this.state.times}/>
            </select>
        } else {
            tourInfo = <div></div>
        }
        return (
            <div className='whole-page'>
                <div className='page-top'>
                    <br></br>
                    <div className='title-anchor'>
                        <a href='/'>
                            <img src={require('../img/logoWhite.png')} alt='Just Kids Preschool Logo' className='center' />
                            <h3 className='page-name text-center' >100% Chinese Immersion Program</h3>
                        </a>

                    </div>

                    <Navbar />
                    <div className='container mt-5'>
                            <div id="carouselIndicators" className="carousel slide" data-ride="carousel">
                                <ol className="carousel-indicators">
                                    <li data-target="#carouselIndicators" data-slide-to="0" className="active"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="1"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="2"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="3"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="4"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="5"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="6"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="7"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="8"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="9"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="10"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="11"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="12"></li>
                                    <li data-target="#carouselIndicators" data-slide-to="13"></li>
                                </ol>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p00.jpg')} alt="First slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p0.jpg')} alt="Second slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p1.jpg')} alt="Third slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p2.jpg')} alt="Fourth slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p3.jpg')} alt="Fifth slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p4.jpg')} alt="Sixth slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img portrait" src={require('../img/gallery/p5.jpg')} alt="Seventh slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img portrait" src={require('../img/gallery/p6.jpg')} alt="Eighth slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img portrait" src={require('../img/gallery/p7.jpg')} alt="Nineth slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img portrait" src={require('../img/gallery/p8.jpg')} alt="Tenth slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p9.jpg')} alt="11th slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img portrait" src={require('../img/gallery/p10.jpg')} alt="12th slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img portrait" src={require('../img/gallery/p11.jpg')} alt="13th slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img portrait" src={require('../img/gallery/p12.jpg')} alt="14th slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p13.jpg')} alt="15th slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img portrait" src={require('../img/gallery/p14.jpg')} alt="16th slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img portrait" src={require('../img/gallery/p15.jpg')} alt="17th slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p16.jpg')} alt="18th slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p17.jpg')} alt="19th slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p18.jpg')} alt="20th slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p19.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p20.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p21.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p22.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p23.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p24.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p25.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p26.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p27.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p28.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p29.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p30.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p31.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p32.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p33.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p34.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p35.jpg')} alt="slide"></img>
                                    </div>
                                    <div className="carousel-item">
                                    <img className="d-block w-100 img" src={require('../img/gallery/p36.jpg')} alt="slide"></img>
                                    </div>
                                </div>
                                <a className="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
                                    <span className='fa fa-chevron-circle-left' aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
                                    <span className='fa fa-chevron-circle-right' aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>

                        </div>
                    <br></br><br></br><br></br><br></br><br></br>
                    
                </div>

                <div className='container'>
                    <div className='philosophy-lower-container mt-5'>
                        {this.state.showModal && <Modal />} 
                        
                        <h3 className='topic-header'>Welcome</h3>
                        <p>Welcome to Just Kids Preschool! We are excited about working	
                            together and forming a relationship with you and your child. 
                            At Just Kids Preschool, we implement the teachings of four philosophers, 
                            Waldorf, Montessori, Reggio Emilia and High Scope. Each of the owls in our 
                            logo represents these great philosophers. Children in our program are 
                            actively involved in meeting new friends and discovering the world around them. 
                            We will foster social, physical, cognitive and emotional skills and establish a 
                            foundation of academic success. 
                        </p>
                        <p>We look forward to getting to know each child as we grow together this year. 
                            Our classes will provide each child with a safe, nurturing, engaging, 
                            and loving learning environment. We will develop real meaning and 
                            understanding of the environment and the world around the students through playful, 
                            open-ended learning.
                        </p>
                        <p>
                            Now accepting ELS vouchers!
                        </p>
                    </div>
                    <div className='philosophy-lower-container mt-5'>
                        <h3 className='topic-header'>The benefits of learning another language</h3>
                        <ul>
                            <li>Learning faster and easier</li>
                            <li>Improving problem-solving skills and creativity</li>
                            <li>Enhancing future career oppotunities</li>
                            <li>Connecting with other cultures</li>
                            <li>Preventing age-related mental illness </li>
                        </ul>
                        <p>To learn more about the benefits of learning more than one language you can check out our resources here:</p>
                            <ul className='mt-2'>
                                <li>
                                    <a href='https://www.ertheo.com/blog/en/learning-a-second-language/?fbclid=IwAR1gsNRBqAAb7xju3SF9j7Zd1R4NrizAnIdXXm-N03paMi7pUpZ7g8YUpWo' target="blank">
                                        Benefits of Learning a Second Language as a Child
                                    </a>
                                </li>
                                <li>
                                    <a href='https://www.leadwithlanguages.org/why-learn-languages/early-childhood-elementary/?fbclid=IwAR2du04WJJaeTtR-TzYREC34oUPjwQERJ3bxwUOLFvb4GTj1b_v84D4Xf1w' target="blank">
                                        Why Learn Languages - Early Childhood & Elementary
                                    </a>
                                </li>
                                <li>
                                    <a href='https://www.sciencedirect.com/science/article/pii/S1877042811025997/pdf?md5=8d8e8779d4ed0258ebfcb4d333379ba4&pid=1-s2.0-S1877042811025997-main.pdf' target="blank">
                                        Procedia - Social and Behavioral Sciences - Foreign language learning during childhood 
                                    </a>
                                </li>
                                <li>
                                    <a href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6168212/?fbclid=IwAR2ELMBNt17Q2IaT40U1c7X5ukS6X3ZZNSXRf-EDseIfw2Z_dYZRNaEgTmg' target="blank">
                                        Bilingualism in the Early Years: What the Science Says
                                    </a>
                                </li>
                            </ul>


                    </div>
                </div>
                {/* {<div className='container'>
                <div className='philosophy-lower-container mt-5'>
                <section-flex id='booking'>
                    <article className='bg-light-blue'>
                        <h3 className='topic-header mt-3 mb-3'>Available Tour Dates</h3>
                        <div className="center-calendar mb-3">
                            <Calendar
                                className='responsive-calendar'
                                value={this.state.dates}
                                // onChange={this.onClickDate}
                                calendarClassName="responsive-calendar"
                                minimumDate={utils().getToday()}
                                shouldHighlightWeekends
                            />
                        </div>
                    </article>
                    <article className='bg-light-blue'>
                        <h3 className='topic-header mt-3 mb-3'>Book a Tour</h3>
                        <p>
                            If there are no dates available, please contact us <a href="/contact">here</a> <br></br>
                        </p>
                        <div className="mt-3 mb-3">
                            <div>
                                <div className="">
                                    <div>Select Date: </div>
                                    <select onChange={(e) => this.onClickDate(e.target.value)}>
                                        <AdminEditToursOptions options={this.state.dates} onClickDate={this.onClickDate} />
                                    </select>
                                </div>
                                <br></br>
                                <div>Select Time: </div>
                                <div>
                                    {tourInfo}
                                </div>
                                <form className='mt-2'>
                                    <div className='mt-2'>
                                        <label className="mr-2">Name: </label>
                                        <input
                                            className='mt-4'
                                            type='text'
                                            placeholder='Name'
                                            value={this.state.tourName}
                                            onChange={(e) => this.setState({ tourName: e.target.value })}
                                        />
                                        <br></br>
                                        <label className="mr-2">Email: </label>
                                        <input
                                            className='mt-4'
                                            type='text'
                                            placeholder='email@example.com'
                                            value={this.state.tourEmail}
                                            onChange={(e) => this.setState({ tourEmail: e.target.value })}
                                        />
                                        <br></br>
                                        <label className="mr-2">Phone: </label>
                                        <input
                                            className='mt-3 mb-4'
                                            type='text'
                                            placeholder='xxx-xxx-xxxx'
                                            value={this.state.tourPhone}
                                            onChange={(e) => this.setState({ tourPhone: e.target.value })}
                                        />
                                    </div>

                                </form>
                                <div className="btn btn-info btn-submit mt-3 btn-bot"
                                    onClick={() => {
                                        this.handleSubmitTour();
                                    }}
                                >Book Tour</div>
                            </div>
                        </div>
                    </article>

                </section-flex>
                    
                </div>

                </div>} */}

                {/* <div className='cards-container mt-5 pt-4'>
                    <div className="card text-white bg-primary mb-3 card1">
                        <div className="card-header">Rayne</div>
                        <div className="card-body">
                            <p className="card-text">I'm Evil >:)</p>
                        </div>
                    </div>
                    
                    <div className="card text-white bg-secondary mb-3 card1">
                        <div className="card-header">Kaylee</div>
                        <div className="card-body">
                            <p className="card-text">I like to whine :(</p>
                        </div>
                    </div>

                    <div className="card text-white bg-success mb-3 card1">
                        <div className="card-header">Zayne</div>
                        <div className="card-body">
                            <p className="card-text">Give me Food!</p>
                        </div>
                    </div>

                </div> */}
            
                <div>
                    
                </div>
                <Footer />
            </div>
        )
    }
}

export default Home;