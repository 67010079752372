import React from 'react';
import './Babysit.css';
import Navbar from '../components/Navbar.jsx';

class Curriculum extends React.Component {
    render() {
        return (
            <div>
                <div className='page-top'>
                    <br></br>
                    <div className='title-anchor'>
                        <a href='/'>
                            <img src={require('../img/logoWhite.png')} alt='Just Kids Preschool Logo' className='center' />
                            <h3 className='page-name text-center under-logo' >100% Chinese Immersion Program</h3>
                        </a>

                    </div>
                    <Navbar />
                    <div className='container container-inner babysit-container mt-5'>
                        <br></br>

                        <h2 className='page-name'>Babysitting Service</h2>
                        <p>​Contact us about feedbacks, updates, and questions.</p>
                        {/* <iframe src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FLos_Angeles&amp;src=aW5mby5qdXN0a2lkc3ByZXNjaG9vbEBnbWFpbC5jb20&amp;src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&amp;src=ZW4udXNhI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23039BE5&amp;color=%2333B679&amp;color=%230B8043&amp;mode=WEEK"
                            className='calendar'
                            title='babysit' 
                            style={{border: "0", width:"100%", height:"70%"}}
                            frameborder="0"
                            scrolling="no">
                        </iframe> */}
                        <iframe src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FLos_Angeles&amp;src=aW5mby5qdXN0a2lkc3ByZXNjaG9vbEBnbWFpbC5jb20&amp;color=%23039BE5&amp;mode=WEEK" 
                        className='calendar'
                        title='babysit' 
                        style={{border: "0", width:"100%", height:"70%"}}
                        frameborder="0" 
                        scrolling="no"></iframe>
                        <br></br>
                    </div>
                    <br></br><br></br><br></br><br></br><br></br>
                </div>

            </div>
        )
    }
}

export default Curriculum;

//