import React from 'react';
import { Link } from 'react-router-dom';

const AdminEnrollmentListEntry = (props) => {
    const applicationEntry = props.applicationEntry;
    return (
        <tr key={applicationEntry.info.formID}>
            <th><Link to={{
                pathname: '/admin/enrollmentform',
                aboutProps: applicationEntry.info
            }} >{applicationEntry.info.formID}</Link></th>
            <th><Link to={{
                pathname: '/admin/enrollmentform',
                aboutProps: applicationEntry.info
            }} >{applicationEntry.info.studentFirstName} {applicationEntry.info.studentLastName}</Link></th>
            <th><Link to={{
                pathname: '/admin/enrollmentform',
                aboutProps: applicationEntry.info
            }} >{applicationEntry.info.p1FirstName} {applicationEntry.info.p1LastName}</Link></th>
            <th>{applicationEntry.info.p1Email}</th>
        </tr>
    )
}

export default AdminEnrollmentListEntry;