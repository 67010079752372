import React from 'react';
import { NavLink } from 'react-router-dom';
import './NavbarAdmin.css';
import {handleLogout} from '../pages/AdminLogin';

const NavbarAdmin = () => {
    return (
        <nav className="navbar navbar-expand-md navbar-light">
            <button className="navbar-toggler ml-auto mb-2 bg-light" type="button"
                data-toggle="collapse" data-target="#myNavbar"
            >
                <span className="fa fa-bars"></span>
            </button>
            <div className="collapse navbar-collapse" id="myNavbar">
                <div className="container-fluid">
                    <div className="row">
                        {/* sidebar */}
                        <div className="col-xl-3 col-lg-3 col-md-3 sidebar fixed-top">
                            <NavLink to="/admin/home" className="navbar-brand text-white d-block max-auto text-center py-3 mb-4 bottom-border">
                                Admin Tools
                            </NavLink>
                            <ul className="navbar-nav flex-column mt-4">
                                <li className="nav-item">
                                    <NavLink activeClassName="current" className="nav-link text-white p-3 mb-2" to="/admin/home">
                                        <i className="fa fa-home text-light fa-lg mr-3"></i>
                                        Home
                                </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeClassName="current" className="nav-link text-white p-3 mb-2 sidebar-link" to="/admin/enrollment">
                                        <i className="fa fa-user text-light fa-lg mr-3"></i>
                                        Applications
                                </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeClassName="current" className="nav-link text-white p-3 mb-2 sidebar-link" to="/admin/messages">
                                        <i className="fa fa-envelope text-light fa-lg mr-3"></i>
                                        Inbox
                                </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeClassName="current" className="nav-link text-white p-3 mb-2 sidebar-link" to="/admin/createtours">
                                        <i className="fa fa-edit text-light fa-lg mr-3"></i>
                                        Create Tours
                                </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeClassName="current" className="nav-link text-white p-3 mb-2 sidebar-link" to="/admin/edittours">
                                        <i className="fa fa-edit text-light fa-lg mr-3"></i>
                                        Edit Tours
                                </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeClassName="current" className="nav-link text-white p-3 mb-2 sidebar-link" to="/admin/login" onClick={() => handleLogout()}>
                                        <i className="fa fa-sign-out text-light fa-lg mr-3"></i>
                                        Log out
                                </NavLink>
                                </li>
                            </ul>
                        </div>
                        {/* end of sidebar */}
                        {/* top of nav */}
                        <div className="col-lx-9 col-lg-9 col-md-9 col-sm-9 ml-auto fixed-top py-2 top-title">
                            <div className="row">
                                <div className="col-md-4">
                                    <h4 className="text-light text-uppercase mb-0">Dashboard</h4>
                                </div>
                                <div className="col-md-5">

                                </div>
                                <div className="col-md-3">

                                </div>
                            </div>
                        </div>
                        {/* end of nav */}
                    </div>
                </div>
            </div>
        </nav >
    )
}

export default NavbarAdmin;