import React from 'react';
import HomeToursTimeEntry from './HomeToursTimeEntry';

const HomeToursTime = (props) => {
    const times = props.times;
    let btimes = 
        ["7:45",
        "8:30",
        "9:15",
        "10:00",
        "10:45",
        "11:30",
        "12:15",
        "1:00",
        "1:45",
        "2:30",
        "3:15",
        "4:00",
        "4:45",
        "5:30",
        "6:30",]

    
    if(!times) {
        return <div></div>
    }
    return (
        btimes.map( (time, index) => {
            if(times[time].tour && times[time].unbooked) {
                return <HomeToursTimeEntry key={index} timeEntry={times} time={time}/>
            }
            return null;
        })
    )

}

export default HomeToursTime;