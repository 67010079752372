import React from 'react';
import {Modal} from './TourModal'
import './Staff.css';
import Navbar from '../components/Navbar.jsx';
import Footer from '../components/Footer.jsx';

class Staff extends React.Component {
    render() {
        return (
            <div className='whole-page'>
                <Modal/>
                <div className='page-top'>
                    <br></br>
                    <div className='title-anchor'>
                        <a href='/'>
                            <img src={require('../img/logoWhite.png')} alt='Just Kids Preschool Logo' className='center' />
                            <h3 className='page-name text-center' >100% Chinese Immersion Program</h3>
                        </a>

                    </div>
                    <Navbar />
                    <div className='container container-inner mt-5'>
                        <br></br>

                        <h2 className='page-name'>Staff</h2>
                        <br></br>
                    </div>
                    <br></br><br></br><br></br><br></br><br></br>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Staff;