import React from 'react';

const AdminMessagesListEntry = (props) => {
    const messageEntry = props.messageEntry;
    return (
        <tr key={messageEntry.info.email}>
            <th>{messageEntry.info.created}</th>
            <th>{messageEntry.info.firstName}</th>
            <th>{messageEntry.info.lastName}</th>
            <th><a href={'mailto:' + messageEntry.info.email + '?subject=Re:Just Kids Preschool Message'}>{messageEntry.info.email}</a></th>
            <th>{messageEntry.info.message}</th>
            <th><button href='#' className='text-danger' 
                onClick={() => 
                    props.deleteMessage(messageEntry.info.created)
                    }>
                <i className="fa fa-times-circle"></i>
            </button></th>
        </tr>
    )
}

export default AdminMessagesListEntry;