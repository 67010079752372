import React from 'react';
import {Modal} from './TourModal'
import './Contact.css';
import Navbar from '../components/Navbar.jsx';
import Footer from '../components/Footer.jsx';
import 'firebase/firestore';
import {auth, db} from './firebaseConfig.js'

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            created: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: '',
        }
    }

    handleSubmit() {
        auth.signInWithEmailAndPassword('guest@justkidspreschool.org', 'test1234')
            .then(() => console.log('Guest is logged in'))
            .then(() => {
                this.setState({created: Date.now()}, () => {
                    let info = this.state;
                    let docRef = db.collection('email').doc(""+this.state.created);
                    docRef.set({
                        info
                    })
                        .catch((err) => {
                            console.log('Error setting documents', err);
                        });
                    this.setState({
                        created: '',
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        message: '',
                    })
                    alert('Your message has been sent.')
                })
            })
    }

    render() {
        return (
            <div className='whole-page'>
                <Modal/>
                <div className='page-top'>
                    <br></br>
                    <div className='title-anchor'>
                        <a href='/'>
                            <img src={require('../img/logoWhite.png')} alt='Just Kids Preschool Logo' className='center' />
                            <h3 className='page-name text-center' >100% Chinese Immersion Program</h3>
                        </a>

                    </div>
                    <Navbar />
                    <div className='container container-inner-contact mt-5'>
                        <br></br>
                        <h1 className='page-name'>Contact Us</h1>
                        <div className='form-line' id='contactName'>
                            <label className='contact-title no-margin'>Full Name *</label><br />
                            <div className='field-element'>
                                <input
                                    className={'inline'}
                                    type='text'
                                    id='FirstName'
                                    placeholder='First Name'
                                    value={this.state.firstName}
                                    onChange={(e) => this.setState({ firstName: e.target.value })}
                                />
                                <input
                                    className={'inline lastName'}
                                    type='text'
                                    id='LastName'
                                    placeholder='Last Name'
                                    value={this.state.lastName}
                                    onChange={(e) => this.setState({ lastName: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='form-line' id='contactEmail'>
                            <label className='contact-title no-margin'>Email *</label>
                            <div className='field-element'>
                                <input
                                    className={'inline'}
                                    type='text'
                                    id='Email'
                                    placeholder='Email'
                                    value={this.state.email}
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='form-line' id='contactPhone'>
                            <label className='contact-title no-margin'>Phone *</label>
                            <div className='field-element'>
                                <input
                                    className={'inline'}
                                    type='text'
                                    id='Phone'
                                    placeholder='Phone'
                                    value={this.state.phone}
                                    onChange={(e) => this.setState({ phone: e.target.value })}
                                />
                            </div>
                        </div>
                        <div id='moreInfo'>
                            <label className='contact-title no-margin'>Message</label>
                            <div className='in-line mb-3' id='childName'>
                                <textarea
                                    className='message-text contact-message'
                                    // style={{ width: '100%', fontFamily: 'Arial', fontSize: '.7em' }}
                                    name="message"
                                    cols="30"
                                    rows="5"
                                    value={this.state.message}
                                    onChange={(e) => this.setState({ message: e.target.value })}
                                ></textarea>
                            </div>
                        </div>
                        <div>
                            <button className="btn btn-info btn-submit mb-3 contact-btn"
                                onClick={() => {
                                    this.handleSubmit(this.state);
                                }}
                            >Submit</button>
                        </div>
                    </div>
                    <br></br><br></br><br></br><br></br><br></br>
                </div>
                <div className='container'>
                    <h1>Map</h1>
                    <p>Just Kids Preschool<br />
                        5727 Geary Blvd<br />
                        San Francisco, CA 94121</p>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.4288876974333!2d-122.48317658468221!3d37.77998747975858!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085870fd991f5a9%3A0x43759160bfbc1095!2s5727%20Geary%20Blvd%2C%20San%20Francisco%2C%20CA%2094121!5e0!3m2!1sen!2sus!4v1593204140551!5m2!1sen!2sus" title="map" width="90%" height="300px" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Contact;